/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    // Colors
    --color-black: #{$black};
    --color-white: #{$white};
    --color-green: #{$green};
    --color-orange: #{$default-primary-base-color};
    --color-gray85: #d9d9d9;
    --color-taupe-gray: #878787;
    --color-cultured: #f7f7f7;
    --color-dark-charcoal: #333333;

    --color-gray: #f3f3f2;
    --color-dark-gray: #dddddd;
    --color-karaka: #0a0903;
    --primary-divider-color: #e0e0e0;
    --secondary-error-color: #fff;
    --primary-error-color: #ff4a4a;
    --secondary-success-color: #fff;
    --primary-success-color: #359e64;
    --secondary-info-color: var(--color-karaka);
    --primary-info-color: #f5df40;
    --body-content-color: var(--color-black);

    // Placeholders
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(
        to right,
        #ebebeb 0,
        var(--placeholder-gradient-color) 15%,
        #ebebeb 30%
    );
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderAnimation 2s linear infinite;

    --shake-animation: shake 820ms 0.5ms cubic-bezier(0.36, 0.07, 0.19, 0.97)
        both;

    // Declare imported primary colors to change the theme's colors
    // "imported_..." variables are set up in Admin panel -> Stores -> Configuration -> Scandipwa -> Color Configuration
    // They have higher priority than "default_..." variables defined in code.
    --primary-base-color: var(
        --imported_primary_base_color,
        #{$default-primary-base-color}
    );
    --primary-dark-color: var(
        --imported_primary_dark_color,
        #{$default-primary-dark-color}
    );
    --primary-light-color: var(
        --imported_primary_light_color,
        #{$default-primary-light-color}
    );

    --secondary-base-color: var(
        --imported_secondary_base_color,
        #{$default-secondary-base-color}
    );
    --secondary-dark-color: var(
        --imported_secondary_dark_color,
        #{$default-secondary-dark-color}
    );
    --secondary-light-color: var(
        --imported_secondary_light_color,
        #{$default-secondary-light-color}
    );

    --grey-base-color: var(
        --imported_grey_base_color,
        #{$default-grey-base-color}
    );
    --grey-dark-color: var(
        --imported_grey_dark_color,
        #{$default-grey-dark-color}
    );
    --grey-light-color: var(
        --imported_grey_light_color,
        #{$default-grey-light-color}
    );

    --taupe-grey-base-color: #{$default-taupe-grey-base-color};
    --taupe-grey-dark-color: #{$default-taupe-grey-dark-color};
    --taupe-grey-light-color: #{$default-taupe-grey-light-color};
}
