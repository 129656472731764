/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    inset-inline-start: 0px;
    inset-block-start: 0px;
    display: block;
    margin-inline: auto;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: inline-start;
    height: 100%;
    min-height: 1px;
    display: none;

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

/*slick item space*/

.slick-space {
    &-5 {
        .slick-list {
            margin-inline: -5px;

            .slick-slide {
                padding-inline: 5px;
            }
        }
    }

    &-10 {
        .slick-list {
            margin-inline: -10px;

            .slick-slide {
                padding-inline: 10px;
            }
        }
    }

    &-15 {
        .slick-list {
            margin-inline: -15px;

            .slick-slide {
                padding-inline: 15px;
            }
        }
    }
}

/*slick slick arrow outside*/

.ContentWrapper {
    &.slick-arrow-outside {
        padding-inline: 30px;
        max-width: 1480px;

        .slick-arrow {
            inset-block-start: 42%;
            background-color: transparent;

            &.slick-prev {
                inset-inline-start: -30px;
            }

            &.slick-next {
                inset-inline-end: -30px;
            }
        }

        @include desktop-lg-up {
            padding-inline: 46px;
            max-width: 1512px;

            .slick-arrow {
                &.slick-prev {
                    inset-inline-start: -46px;
                }

                &.slick-next {
                    inset-inline-end: -46px;
                }
            }
        }

        @include desktop-xl-up {
            padding-inline: 70px;
            max-width: 1560px;

            .slick-arrow {
                &.slick-prev {
                    inset-inline-start: -70px;
                }

                &.slick-next {
                    inset-inline-end: -70px;
                }
            }
        }

        @include mobile-exsmall {
            padding-inline: 15px;

            .slick-arrow {
                &.slick-prev {
                    inset-inline-start: -15px;
                }

                &.slick-next {
                    inset-inline-end: -15px;
                }
            }
        }
    }
}