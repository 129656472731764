/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

.ContactForm {
    textarea {
        width: 100%;
    }

    .Field-Wrapper:first-of-type .Field {
        margin-block-start: 0;
    }
}
