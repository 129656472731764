/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --link-color: var(--color-orange);
    --link-hover: var(--color-black);
    --link-black: var(--color-black);
    --link-black-hover: var(--color-orange);
}

a {
    color: var(--link-color);

    @include hoverable {
        &:hover,
        &:active {
            color: var(--link-hover);
        }
    }

    &.link-black {
        color: var(--color-black);

        svg {
            fill: var(--color-black);
        }

        &:hover,
        &:active {
            color: var(--link-color);

            svg {
                fill: var(--link-color);
            }
        }
    }

    &.link-dark-charcoal {
        color: var(--color-dark-charcoal);

        &:hover,
        &:active {
            color: var(--link-color);
        }
    }

    &.tdu {
        text-decoration: underline;
        
        &:hover,
        &:active {
            text-decoration: none;
        }
    }

    &.hover-tdu {
        &:hover,
        &:active {
            text-decoration: underline;
        }
    }
}
