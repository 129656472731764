/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.NoMatch {
    &-Wrapper {
        max-width: 1280px;
    }
    
    &-Space-20 {
        padding-block-end: 20px;
    }

    h5 {
        margin-block-end: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }

    &-Disc {
        li {
            position: relative;
            padding-block: 5px;
            padding-inline-start: 20px;
            
            &::before {
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: var(--color-black);
                inset-inline-start: 0px;
                inset-block-start: 11px;
            }
        }
    }

    &-Heading {
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        padding-block-start: 15px;
        padding-block-end: 30px;
        
        @include mobile {
            font-size: 20px;
            line-height: 20px;
            padding-block: 20px;
        }
    }
}

@include mobile {
    .Breadcrumbs {
        display: block !important;
    }
}
