/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.hidden-desktop-lg-down {
    @include desktop-lg-down {
        display: none;
    }
}

.hidden-desktop-lg-up {
    @include desktop-lg-up {
        display: none;
    }
}

.hidden-desktop-xl-down {
    @include desktop-xl-down {
        display: none;
    }
}

.hidden-desktop-xl-up {
    @include desktop-xl-up {
        display: none;
    }
}

.hidden-desktop {
    @include desktop {
        display: none;
    }
}

.hidden-tablet {
    @include tablet {
        display: none;
    }
}

.hidden-mobile {
    @include mobile {
        display: none;
    }
}

br {
    &.mobile-only {
        content: "";
        display: inline;
        vertical-align: top;
        zoom: 1;
        overflow: hidden;
        width: 0.3em;
        float: inline-end;

        @include mobile {
            display: initial;
            content: initial;
            height: auto;
            width: 100%;
            float: initial;
        }

        &::before {
            content: "";
            display: inline-block;
            width: 0.3em;

            @include mobile {
                content: none;
            }
        }
    }
}

.mobile-only {
    @include desktop {
        display: none !important;
    }

    @include mobile {
        display: inline-block;
    }
}
