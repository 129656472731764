/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

.Branches {
    padding-inline: 15px;

    @include desktop {
        display: block;
        position: relative;
        inset-inline-start: 50%;
        transform: translateX(-50%);
        max-width: var(--content-wrapper-width);
        padding-block-end: 50px;
    }

    @include mobile {
        padding-block-end: 40px;
    }

    &-Heading {
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        padding-block-start: 15px;
        padding-block-end: 30px;

        @include mobile {
            font-size: 20px;
            line-height: 20px;
            padding-block: 20px;
        }
    }

    &-Step2 {
        .Branches {
            &-Heading {
                display: flex;
                justify-content: space-between;

                .Branches {
                    &-back {
                        .link-black {
                            padding: 0px;
                        }
                    }
                }

                @include mobile {
                    padding-block-end: 5px;
                }

                @include mobile-small {
                    display: block;

                    .Branches {
                        &-back {
                            padding-block-start: 10px;

                            .link-black {
                                padding: 0px;
                                text-align: start;
                            }
                        }
                    }
                }
            }
        }
    }

    &-inner {

        @include desktop {
            display: grid;
            grid-column-gap: 45px;
            grid-template-columns: 48.5% 48.5%;
        }

        @include tablet {
            display: grid;
            grid-column-gap: 20px;
            grid-template-columns: 49% 49%;
        }

        @include wide-desktop {
            grid-column-gap: 90px;
            grid-template-columns: 46.7% 46.7%;
        }
    }

    &-left {
        @include desktop {}

        @include tablet {}
    }

    &-right {
        @include desktop {

            &-sticky {
                position: sticky;
                inset-block-start: var(--header-total-height);
            }
        }

        @include tablet {}
    }

    &-Detailcontent {
        &-loop {
            margin-block-end: 30px;
        }

        &-hd {
            color: var(--color-black);
            padding-block-end: 10px;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
        }

        &-cont {
            a.BranchItem-Directions {
                margin-block-start: 0px;
                position: relative;
                padding-inline-start: 35px;
                font-size: 14px;
                line-height: 20px;
                display: inline-block;
                color: var(--color-black);
                font-weight: 400;

                svg {
                    position: absolute;
                    inset-inline-start: 3px;
                    width: 15px;
                    height: 20px;
                    inset-block-start: 0px;
                }

                &:hover {
                    svg {
                        fill: var(--color-black);
                    }
                }
            }
        }

        &-Address {
            li {
                margin-block-end: 10px;
                color: var(--color-black);
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                position: relative;
                padding-inline-start: 35px;

                .icon {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    inset-block-start: 0px;
                    inset-inline-start: 0px;
                }
            }
        }
    }

    .BranchItem {
        border: 1px solid var(--color-gray85);
        background-color: var(--color-white);
        position: relative;


        &-arrow {
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: var(--color-orange);
            inset-block-start: 50%;
            inset-inline-end: -11px;
            margin-block-start: -11px;
            rotate: 45deg;
            display: none;
            box-shadow: 3px 0px 4px 0px rgba(0, 0, 0, 0.25);
        }

        &-bg {
            background-color: var(--color-white);
            position: relative;
            z-index: 1;
        }

        &-ExpandableContentButton {
            padding: 20px;
            align-items: flex-start;
        }

        &-ExpandableContentContent {
            padding-inline: 20px;
        }

        &-ExpandableContentHeading {
            color: var(--color-black);

            strong {
                font-weight: 700;

                .BranchItem-miles {
                    display: inline-block;
                    font-weight: 400;
                    padding-inline-start: 10px;
                    padding-block: 0px;
                }
            }


            p {
                display: block;
                padding-block-start: 15px;
            }

            .badge {
                margin-inline-start: 10px;
                background-color: var(--color-orange);
                padding: 5px;
                border-radius: 5px;
                display: inline-block;
                color: var(--color-white);
                font-size: 10px;
                font-weight: 700;
                line-height: 13px;
                text-transform: uppercase;
            }
        }

        p {
            font-size: 12px;
            line-height: 19px;
            font-weight: 400;
            color: var(--color-black);
            margin-block-end: 0px;
        }

        &-miles {
            padding-block-start: 10px;

            p {
                font-weight: 600;
            }
        }

        &-row {
            display: grid;
            column-gap: 43px;
            grid-template-columns: 1fr 1fr;
            padding-block-end: 20px;
            align-items: start;

            strong {
                font-weight: 500;
            }
        }

        &-Directions {
            font-size: 12px;
            line-height: 20px;
            font-weight: 500;
            cursor: pointer;
            color: var(--color-black);
            display: flex;
            align-items: center;

            svg {
                margin-inline-end: 5px;
                fill: var(--color-black);
            }

            &:hover {
                text-decoration: none;
                color: var(--color-orange);

                svg {
                    fill: var(--color-orange);
                }
            }
        }

        &-link {
            margin-inline: 20px;
            padding-block: 20px;
            border-block-start: 1px solid var(--color-gray85);
            display: flex;
            justify-content: space-between;

            >li {
                margin: 0px;
                padding: 0px;
            }

        }

        &-Specialhours {
            margin-inline: 20px;
            padding-block-end: 20px;

            a {
                font-size: 12px;
                line-height: 20px;
                font-weight: 500;
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                    margin-inline-end: 5px;
                    fill: var(--color-black);
                }

                &:hover {
                    text-decoration: none;
                    color: var(--color-orange);

                    svg {
                        fill: var(--color-orange);
                    }
                }
            }
        }

        &-loop {
            padding-block-start: 20px;

            >li {
                padding-inline: 1px;
            }

            >li:not(:last-child) {
                padding-block-end: 20px;
            }
        }

        &-ex {
            img {
                width: 100%;
            }
        }

        &.active {
            border-color: var(--color-orange);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            .BranchItem {
                &-ExpandableContentHeading {
                    color: var(--color-orange);
                }

                &-ExpandableContentButton {
                    svg {
                        fill: #f68927;
                    }
                }

                &-arrow {
                    display: block;

                    @include mobile {
                        display: none;
                    }
                }
            }
        }

        @include mobile {

            &-ExpandableContent {
                border-block-end: 0px;
            }

            &-ExpandableContentButton {
                padding: 15px;
            }

            &-ExpandableContentHeading {
                p {
                    padding-block-start: 10px;
                    line-height: 10px;
                }
            }

            &-ExpandableContentContent {
                padding-inline: 15px;
            }

            &-link {
                margin-inline: 15px;
                padding-block: 15px;
            }

            &-Specialhours {
                margin-inline: 15px;
                padding-block-end: 15px;
            }

            &-row {
                column-gap: 15px;
                padding-block-end: 15px;
                grid-template-columns: 40% 60%;
            }

            &-loop {
                padding-block: 20px;
            }
        }

        @include mobile-small {
            &-link {
                display: grid;
                grid-row-gap: 10px;
            }
        }
    }

    &-back {
        text-align: center;

        .link-black {
            display: block;
            text-align: center;
            padding: 20px;
            font-size: 12px;

            @include mobile {
                padding: 15px;
            }

            svg {
                margin-inline-end: 5px;
            }
        }
    }

    &-Search {
        &InnerWrapper {
            position: relative;
        }

        .Field {
            margin-block-start: 5px;

            &-Label {
                font-size: 12px;
                line-height: 14px;
                font-weight: 400;
                color: var(--color-dark-charcoal);
                padding-block-end: 5px;

                &_isRequired {
                    color: var(--required-text-color);
                }
            }

            input {
                width: 100%;
                padding-inline-end: 45px;
                font-size: 12px;
            }
        }

        .BranchItem {
            &-SearchSvg {
                position: absolute;
                inset-inline-end: 15px;
                inset-block-start: 35px;

                .svg-search {
                    fill: var(--color-taupe-gray);
                }
            }
        }
    }

    &-ExpandableContent {
        background-color: var(--color-cultured);
        border-block-end: 1px solid #c4c4c4;
        padding: 20px;
        position: sticky;
        inset-block-start: var(--header-total-height);
        z-index: 3;

        &Button {
            padding: 0px;
        }

        &Content {
            padding: 0px;
        }

        @include mobile {
            padding: 15px;
        }
    }

    &-ExpandableContentHeading {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-black);

        @include mobile {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &-Time {
        max-width: 220px;

        li {
            font-size: 12px;
            line-height: 19px;
            font-weight: 400;
            color: var(--color-black);

            span {
                display: inline-block;
                width: 50%;
            }
        }

    }
}