/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.Field {
    &_type {
        &_numberWithControls {
            [type="number"] {
                appearance: textfield;
                color: var(--color-black);
                border: 1px solid #c6c6c6;
                font-size: 14px;
                font-weight: 500;
                height: 50px;
                padding: 0;
                order: 2;
                text-align: center;
                width: 64px;
                //pointer-events: none;
                margin: 0 5px;

                &:focus {
                    border: 1px solid var(--input-border-color);
                }

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    appearance: none;
                    margin: 0;
                }

                ~ button {
                    background: transparent;
                    border: 1px solid var(--color-black);
                    border-radius: var(--button-border-radius);
                    color: $black;
                    inset-inline-start: 0;
                    line-height: 0;
                    margin: 0;
                    order: 1;
                    display: flex;
                    width: 50px;
                    height: 50px;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    inset-block-start: 0;

                    svg {
                        fill: var(--color-black);
                    }

                    @include mobile {
                        width: 26px;
                        height: 26px;
                    }

                    @include hoverable {
                        &:hover {
                            border-color: var(--color-orange);

                            svg {
                                fill: var(--color-orange);
                            }
                        }
                    }

                    &:active {
                        border-color: var(--color-orange);

                        svg {
                            fill: var(--color-orange);
                        }
                    }

                    &:first-of-type {
                        inset-inline-start: 0;
                        order: 3;
                    }

                    &:last-of-type {
                        inset-block-end: 0;
                        inset-inline-end: 0;
                        order: 1;
                    }

                    &:disabled {
                        opacity: 0.25;
                        cursor: default;
                        pointer-events: none;
                    }

                    span {
                        @include mobile {
                            inset-block-start: -2px;
                        }
                    }
                }
            }
        }
    }
}
