/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 $white: #fff;
 $black: #000;
 $green: #0b8d00;
 
 $default-primary-base-color: #f68927;
 $default-primary-dark-color: darken($default-primary-base-color, 15%);
 $default-primary-light-color: lighten($default-primary-base-color, 15%);
 
 $default-secondary-base-color: #eee;
 $default-secondary-dark-color: darken($default-secondary-base-color, 15%);
 $default-secondary-light-color: lighten($default-secondary-base-color, 15%);
 
 $default-grey-base-color: #000;
 $default-grey-dark-color: darken($default-grey-base-color, 15%);
 $default-grey-light-color: lighten($default-grey-base-color, 15%);
 
 $default-taupe-grey-base-color: #878787;
 $default-taupe-grey-dark-color: darken($default-taupe-grey-base-color, 15%);
 $default-taupe-grey-light-color: lighten($default-taupe-grey-base-color, 15%);
 
 $font-muli: "Muli", sans-serif;
 $font-common: "Montserrat", sans-serif;
 $font-standard-size: 62.5%;
 