/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --expandable-content-color: var(--color-karaka);
    --expandable-content-divider-color: var(--primary-divider-color);
    --toggle-button-size: 14px;
}

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ExpandableContent {
    @include mobile {
        border-block-start: 1px solid var(--expandable-content-divider-color);

        &:last-of-type {
            border-block-end: 1px solid var(--expandable-content-divider-color);
        }

        .AddIcon,
        .MinusIcon,
        .ChevronIcon {
            width: var(--toggle-button-size);
            height: var(--toggle-button-size);
        }
    }

    &-Button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 10px;
        cursor: pointer;

        svg {
            width: 13px;
            height: 13px;
            cursor: pointer;
        }

        @include mobile {
            padding: 17px 10px;
        }
    }

    &-Heading,
    &-SubHeading {
        display: block;
    }

    &-Heading {
        font-size: 14px;
        font-weight: 600;
        color: var(--color-dark-charcoal);
        line-height: 20px;
        word-break: break-all;

        @include desktop {
            text-transform: none;
        }
        
        @include mobile {
            font-weight: 500;
        }
    }

    &-SubHeading {
        font-weight: 400;
        font-size: 10px;
        line-height: 1.1;

        @include mobile {
            font-size: 12px;
        }
    }

    &-Content {
        // Disabled as it prevents z-index use inside
        // will-change: max-height, padding, opacity;
        transition-property: opacity;
        transition-duration: 200ms;
        max-height: 0;
        overflow: hidden;
        opacity: 0;

        &_isContentExpanded {
            @include expanded-content;
            padding-inline: 10px;
        }
    }

    @include mobile {
        &_isWithoutBorder {
            border: 0;

            &:last-of-type {
                border: 0;
            }
        }
    }
}

.DiscountContent {
    &-ExpandableContentButton {
        padding: 0px;
        justify-content: flex-start;
    }
    
    &-ExpandableContentHeading {
        padding-inline-end: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
    
    &-ExpandableContentContent {
        padding: 0px;
        margin-block-start: 0px;
    }
}
