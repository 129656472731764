/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

:root {
    --home-selling-points-bg: #f2f2f2;
    --home-promotional-bg: #878787;
}

.clearfix:after{display:block;clear:both;content:"";}

@mixin border-radius($top-left: 10px,
$top-right: null,
$bottom-right: null,
$bottom-left: null) {
    -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
    -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
    -ms-border-radius: $top-left $top-right $bottom-right $bottom-left;
    border-radius: $top-left $top-right $bottom-right $bottom-left;
}

.HomePage {
    .CmsPage {

        &,
        &-Wrapper {
            max-width: 100%;
            padding-inline: 0px;
            margin-inline: 0;
        }
    }

    //Home Titel

    h2 {
        text-align: center;
        margin-block-start: 0px;
    }

    //Commen space

    &-space {
        padding-block: 40px;

        @include mobile {
            padding-block: 25px;
        }
    }

    &-xline {
        border-top: 1px solid #e3e3e3;

        @include mobile {
            margin-inline: -15px;
        }
    }

    //promotional

    &-promotional {
        background-position: center;
        display: block;
        width: 100%;

        .ContentWrapper {
            text-align: center;
            height: 125px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        p {
            width: 100%;
        }

        h4,
        h2 {
            color: var(--color-white);
            margin: 0px;
        }

        h4 {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            padding: 0px;
            inset-block-start: auto;
        }

        h2 {
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
            padding-block: 1px;
        }

        @include mobile {
            background-repeat: no-repeat;
            background-size: cover;

            .ContentWrapper {
                height: 130px;
            }

            h4 {
                font-size: 14px;
                line-height: 24px;
            }

            h2 {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                padding-block: 1px;
            }
        }
    }
    
    .HomePage-hero-banner.fxheight{min-height:420px;}
    .HomePage-selling-points.fxheight{min-height:75px;}
    .summer-collections-man.fxheight{min-height:500px;}
    .HomePage-selling-points.fxheight{min-height:75px;}

    /*.HomePage-hero-banner .slick-slider {height:420px;}
    .HomePage-hero-banner .slick-slider.slick-initialized {height: auto;}
    
    .top-cat-man .slick-slider {height:325px;}
    .top-cat-man .slick-slider.slick-initialized {height: auto;}
    
    .top-sellers-man .slick-slider {height:640px;}
    .top-sellers-man .slick-slider.slick-initialized {height: auto;}*/
    
    //Hero-banner
    &-hero-banner {
        line-height: 0;

        .ContentWrapper {
            padding-inline: 0px;
            max-width: 100%;
        }

        .SliderWidget {
            &-FigureImage {

                img {
                    margin-block-start: -1px;
                }
            }

            &-Sliderdiv {
                background-position: center center;
                background-repeat: no-repeat;
                position: relative;
                background-size: cover;
                min-height:auto;
                padding-block-end: 23.45%;
                width:100%;

                @include mobile {
                    padding-block-end: 85%;
                }
            }
            &:not(:first-child) {
			     display: none;
		      }
        }

        .slick-arrow {
            background-color: transparent;

            &::before {
                width: 18px;
                height: 18px;
                border-color: var(--color-white);
            }

            &.slick-prev {
                inset-inline-start: 15px;
                inset-inline-end: auto;
            }

            &.slick-next {
                inset-inline-end: 15px;
                inset-inline-start: auto;
            }
        }

        .slick-dots {
            position: absolute;
            inset-block-end: 15px;

            li {
                line-height: 5px;

                button {
                    border-color: var(--color-white);
                    width: 5px;
                    height: 5px;
                }

                &.slick-active {
                    button {
                        border-color: var(--color-orange);
                        background-color: var(--color-orange);
                    }
                }
            }
        }
    }

    //selling-points

    &-selling-points {
        background-color: var(--home-selling-points-bg);
        padding-block: 26px;
        height: 85px;

        .row {
            display: flex;
            justify-content: space-between;
            gap: 10px;
        }

        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;

            span.icon {
                width: 33px;
                height: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            span.text {
                padding-inline-start: 10px;
            }
        }

        @include wide-desktop {
            a {
                font-size: 16px;
                line-height: 16px;
            }
        }

        @include narrow-desktop {
            a {
                font-size: 14px;
                line-height: 18px;
            }
        }

        @include tablet {
            height: 108px;

            a {
                font-size: 14px;
                line-height: 18px;
                display: initial;

                span.icon {
                    margin: 0px auto;
                }

                span.text {
                    padding-block-start: 5px;
                    padding-inline-start: 0px;
                    text-align: center;
                    display: flex;
                    align-items: start;
                    justify-content: center;
                }
            }
        }

        @include desktop {
            a {
                span.icon {
                    white-space: nowrap;
                }
            }
        }

        @include mobile {
            height: 118px;

            a {
                display: initial;
                font-size: 12px;
                line-height: 14px;

                .mobile-only {
                    display: none;
                }

                span.icon {
                    margin: 0px auto;
                }

                span.text {
                    padding-block-start: 5px;
                    padding-inline-start: 0px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        @include mobile-small {
            height: 129px;

            a {
                font-size: 11px;
                line-height: 13px;
            }
        }

        @include mobile-exsmall {
            height: 126px;

            a {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }
}

//New In

.new-in {
    min-height: 400px;

    @include mobile {
        .HomePage-space {
            padding-block: 0px;
        }
    }

    &-bg {
        background-color: #f2f2f2;

        @include desktop {
            padding: 40px 67px;
            border-radius: 40px 0px;
        }

        @include tablet {
            padding: 30px 40px;
        }

        @include mobile {
            padding: 30px 15px;
            margin-inline: -15px;
        }
    }

    .link-black {
        display: block;
        position: relative;
        width: 100%;

        &.view-all-link {
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            border-bottom: 1px solid var(--color-black);
            width: auto;

            &:hover {
                border-color: var(--color-orange);
            }
        }

        .img-wrapper {
            position: relative;
            padding-bottom: 55.5%;
            display: block;
            overflow: hidden;

            img {
                position: absolute;
                max-width: 100%;
                max-height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%) translateY(-50%);
                object-fit: contain;
            }
            &::after {
                display: block;
                clear: both;
                content: "";
            }
        }

    }

    .label {
        position: absolute;
        inset-block-end: 0px;
        inset-inline-start: 0px;
        inset-inline-end: 0px;
        font-weight: 600;
        background-color: rgba(255, 255, 255, 0.55);
        width: 100%;
        padding: 8px 20px;
        line-height: 24px;

        @include mobile {
            font-size: 12px;
            line-height: 14px;
            padding: 5px;
            text-align: center;
        }

        @include mobile-small {
            font-size: 11px;
        }
    }

    .Button {
        min-width: 150px;
    }

    h2 {
        margin-block-end: 13px;
    }

    .row {
        margin-inline: -10px;
        display: flex;
        flex-wrap: wrap;

        @include mobile {
            margin-inline: -7px;
        }

        .col {
            -ms-flex: 0 0 33.3333333333%;
            flex: 0 0 33.3333333333%;
            max-width: 33.3333333333%;
            padding: 10px;
            display: flex;

            .full-height {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                background-color: #f8f8f8;
                width: 100%;

                .Button_grey_border {
                    text-transform: none;
                }
            }

            @include tablet {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include mobile {
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                padding: 7px;

                &.hidden-mobile {
                    display: none;
                }

                &:nth-child(2) {
                    img {
                        @include border-radius(40px 0px 0px 0px);
                    }
                }

                &:last-child {
                    .full-height {
                        @include border-radius(0px 0px 40px 0px);
                    }
                }
            }

            @include desktop {
                &.hidden-desktop {
                    display: none;
                }
            }
        }
    }
}

// Clearance

.clearance {
    .ProductCard {
        &-LinkInnerWrapper {
            border-color: var(--color-gray85);
        }

        &-AddToCart {
            span {
                position: relative;
                font-size: 0px;

                &::after {
                    content: "Buy Now";
                    font-size: 14px;
                }
            }
        }

        &-Reviews {
            display: none;
        }

        @include mobile {
            border: solid 1px #f2f2f2;
            border-radius: 5px;
            padding: 5px !important;

            &-OfferTimers {
                inset-block-start: -19px;
            }

            .slick-space-5 {
                margin: 0;
            }
        }
    }

    .Button_grey_border {
        text-transform: none;
        width: auto;
        min-width: 223px;
    }

    .ProductPrice {
        display: flex;
        flex-wrap: wrap;
        min-height: 33px;
        padding-block-end: 5px;

        &-Price {
            padding-block-end: 10px;

            @include mobile {
                padding-block-end: 5px;
            }
        }

        &-TierPrice {
            display: none;
        }

        &-Price {
            color: var(--color-orange);
            display: block;
            padding-inline-end: 0px;
            width: 100%;
        }

        &-Vat {
            display: none;
        }

        &-PriceValue {
            font-size: 16px;
            font-weight: 500;

            &::before {
                content: "Deal Price";
                display: inline-block;
                padding-inline-end: 5px;
            }

            @include mobile {
                font-size: 14px;
            }
        }

        del {
            font-size: 14px;
            min-width: 145px;
            text-decoration: none;

            &::before {
                content: "Our Price:";
                display: inline-block;
                padding-inline-end: 5px;
            }

            .ProductPrice-Vat {
                display: block;
                padding-block-start: 10px;
            }

            @include mobile {
                font-size: 12px;

                .ProductPrice-Vat {
                    padding-block-start: 5px;
                }
            }
        }

        &-TierPrice {
            margin-block-end: 0px;
            margin-block-start: 10px;
            min-height: 20px;
            display: block;
            width: 100%;
        }
    }

    .slick-list {
        padding-block-start: 12px !important;
        padding-block-end: 15px !important;
    }

    h2 {
        margin-block-end: 20px;
    }

    .ClickCollect {
        display: none;
    }

    @include desktop {
        .slick-space-5 {
            padding-block-end: 30px;

            .slick-list {
                margin-inline: -12px;

                .slick-slide {
                    padding-inline: 12px;
                }
            }
        }
    }

    @include mobile {
        .ContentWrapper {
            padding-inline: 0px;
        }

        .HomePage-space {
            padding-block: 30px;
        }
    }
}

//summer-collections

.summer-collections {
    .row {
        margin-inline: -15px;
        display: flex;
        flex-wrap: wrap;

        .col {
            flex: 0 0 50%;
            max-width: 50%;
            padding-inline: 15px;
        }
    }

    .item {
        text-align: center;

        .thumb {
            display: block;
            position: relative;
            overflow: hidden;
            padding-block-end: 40%;

            img {
                position: absolute;
                max-width: 100%;
                max-height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%) translateY(-50%);
                object-fit: contain;
                border-radius: 0px 30px;
            }
        }

        .name {
            font-weight: 500;
            color: var(--color-dark-charcoal);
            font-size: 20px;
            line-height: 24px;
            padding-block-start: 20px;
            margin-block-end: 20px;
        }

        .off {
            font-weight: 600;
            text-transform: capitalize;
            color: var(--color-black);
            font-size: 18px;
            line-height: 22px;
            padding-block-start: 12px;
            padding-block-end: 20px;
        }

        .Button {
            min-width: 180px;
            border-width: 1px;
            text-transform: none;
        }
    }

    @include mobile {
        .row {
            margin-inline: 0px;

            .col {
                flex: 0 0 100%;
                max-width: 100%;
                padding-inline: 0px;

                &:first-child {
                    padding-block-end: 30px;
                }
            }
        }

        .item {
            .name {
                font-size: 14px;
                line-height: 16px;
                padding-block-start: 10px;
                margin-block-end: 10px;
            }

            .off {
                font-size: 12px;
                line-height: 16px;
                padding-block-start: 6px;
                padding-block-end: 10px;
            }

            .Button {
                width: 130px;
            }
        }
    }
}

// The Brightest Spark

.brightest {
    @include desktop {
        .row {
            display: grid;
            column-gap: 4%;
            text-align: center;
            grid-template-columns: 48% 48%;

            .col {

                &-left,
                &-right {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                }

                &-left {
                    .thumb {
                        margin-inline-end: -53px;
                    }
                }

                &-right {
                    .thumb {
                        margin-inline-start: -53px;
                        order: 2;
                    }

                    .info {
                        order: 1;
                    }
                }
            }
        }

        .thumb {
            width: calc(100% + 53px);

            img {
                max-width: 100%;
                @include border-radius(0px, 30px);
                border: 12px solid var(--color-white);
            }
        }

        .info {
            padding-block: 20px;

            h3 {
                font-size: 16px;
                font-weight: 600;
                margin-block-start: 0px;
                margin-block-end: 8px;
            }

            p {
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                color: var(--color-dark-charcoal);
                margin-block-end: 20px;
                min-height: 48px;
            }

            .Button {
                width: 180px;
            }
        }
    }

    @include tablet {
        .row {
            column-gap: 2%;
            grid-template-columns: 49% 49%;

            .col {
                &-left {
                    .thumb {
                        margin-inline-end: -20px;
                    }
                }

                &-right {
                    .thumb {
                        margin-inline-start: -20px;
                    }
                }
            }
        }

        .thumb {
            width: calc(100% + 20px);

            img {
                border: 6px solid var(--color-white);
            }
        }

        .info {
            padding-block: 10px;

            h3 {
                font-size: 16px;
                margin-block-end: 6px;
            }

            p {
                line-height: 18px;
                margin-block-end: 10px;
                min-height: 48px;
            }

            .Button {
                width: 180px;
            }
        }
    }

    @include mobile {
        background-color: #f2f2f2;

        .row {
            display: grid;
            column-gap: 15px;
            text-align: center;
            grid-template-columns: 1fr 1fr;
        }

        .info {
            padding-block-start: 10px;

            h3 {
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                margin-block-start: 0px;
                margin-block-end: 7px;
            }

            .Button {
                width: 125px;
                height: 40px;
            }
        }
    }
}

//#nationallighting

.instawidget {
    text-align: center;

    h2 {
        color: var(--color-dark-charcoal);
        margin-block-end: 15px;
    }

    p {
        font-size: 12px;
        margin-block-end: 0px;
        padding-inline: 15px;
    }

    .Button {
        width: 229px;
    }

    .taggbox {
        position: static;
        margin-block: 20px;

        .splide__arrows {
            position: initial;

            .tb_hs_arrow {
                inset-block-start: 50%;
                margin-block-start: -30px;
            }
        }
    }

    @include mobile {
        .ContentWrapper {
            padding-inline: 0px;
        }

        h2 {
            margin-block-end: 18px;
        }

        .Button {
            width: 165px;
        }
    }

    .electricblaze-state-mounted {
        margin-block-end: 20px;
        margin-block-start: 17px;
    }

    .xBbfJ {
        padding: 0px;
    }

    .ibcmqI {
        padding: 0px;
    }

    .gzleye {
        margin: 0px;
    }

    .bSrCXj {
        margin: 0px;
    }

    .jGUsQp,
    .bFALyz {
        background-color: transparent;
        cursor: pointer;
        width: auto;
        height: auto;
        border: 0px;
        border-radius: 0px;

        svg {
            path {
                fill: #000 !important;
            }
        }

        &:hover {
            background-color: transparent;
            cursor: pointer;
            width: auto;
            height: auto;
            border: 0px;
            border-radius: 0px;
        }
    }
}

.tb_post_modal_modal_head {
    position: initial;
}

.description {
    h3 {
        font-size: 18px;
        line-height: 22px;
        margin-block-start: 0px;
        margin-block-end: 22px;
        color: var(--color-black);
        font-weight: 600;
    }

    p {
        font-size: 16px;
        line-height: 24px;
        margin-block-end: 25px;

        &:last-child {
            margin-block-end: 0px;
        }
    }

    @include mobile {
        h3 {
            font-size: 14px;
            line-height: 24px;
            margin-block-end: 12px;
        }

        p {
            font-size: 12px;
            line-height: 15px;
            margin-block-end: 12px;

            &:last-child {
                margin-block-end: 0px;
            }
        }
    }

    &-man {
        .HomePage {
            &-space {
                padding-block-end: 0px;

                h3,
                h1 {
                    font-size: 18px;
                    line-height: 22px;
                    margin-block-start: 0px;
                    margin-block-end: 22px;
                    color: var(--color-black);
                    font-weight: 600;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    margin-block-end: 25px;

                    &:last-child {
                        margin-block-end: 0px;
                    }
                }

                @include mobile {

                    h3,
                    h1 {
                        font-size: 14px;
                        line-height: 24px;
                        margin-block-end: 12px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 15px;
                        margin-block-end: 12px;

                        &:last-child {
                            margin-block-end: 0px;
                        }
                    }
                }
            }
        }
    }
}

.Button_grey {
    @include mobile {
        height: 40px;
    }
}

.ss-top-sellers {
    .HomePage-space {
        padding-block-end: 0px;
    }
}
