/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
 
.Pagination {
    display: flex;
    justify-content: center;
    height: 32px;
    margin-inline: auto;
    z-index: 2;
    margin-block-start: 40px;
    padding-inline: 15px;
    
    &-ListItem {
        display: block;
        margin-inline: 5px;
        
        &:first-child {
            margin-inline-start: 0px;
        }
        
        &:last-child {
            margin-inline-end: 0px;
        }
    }
    
    .svg-pagination-arrow {
        cursor: pointer;
        display: block;
        stroke: var(--color-orange);
        width: 6px;
        height: 12px;
        
        &-left {
            transform: rotate(180deg);
        }
    }
    
    @include desktop {
        order: 1;
    }
    
    @include mobile {
        margin-block: 30px;
    }
}
