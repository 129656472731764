/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.AmastyMyReturnsPage {
    .amreturns {
        &-order-select {
            /*border:1px solid var(--color-gray85);*/
            padding: 20px;
            margin-block-start: 20px;
            display: flex;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
        }
        
        &-title-container {
            display: flex;
            width: auto;
        }
        
        &-select-title {
            font-weight: bold;
            font-size: 14px;
            line-height: 14px;
        }
        
        &-orders-container {
            flex: 1 1 auto;
            width: 1%;
            display: flex;
            align-items: center;
            
            .Field-Wrapper {
                flex: 1 1 auto;
                width: 1%;
                padding-inline: 15px;
                
                .Field {
                    margin-block-start: 0px;
                }
            }
            
            .amreturn-btn {
                display: flex;
                width: auto;
            }
        }

        @include mobile {
            &-order-select {
                display: inherit;
                padding: 0px;
            }
            
            &-title-container {
                display: inherit;
                padding-block-end: 5px;
            }
            
            &-orders-container {
                width: auto;
                
                .Field-Wrapper {
                    padding-inline-start: 0px;
                    padding-inline-end: 5px;
                }
            }
        }
    }

    .amrma {
        &-table {
            font-size: 14px;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            height: max-content;
            
            &-container {
                margin-block-start: 20px;
                
                @include desktop {
                    border: 1px solid var(--color-gray85);
                    padding: 20px;
                }

                .Tablecommon {
                    table {
                        thead {
                            th.status {
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }

        &-image {
            width: 50px;
            height: 50px;
        }
    }

    .message.info {
        margin: 10px 0;
        padding: 12px 20px 12px 25px;
        display: block;
        font-size: 1.3rem;
        background: #fdf0d5;
        color: #6f4400;
        padding-inline-start: 45px;
        position: relative;
    }
}
