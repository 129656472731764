/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    /* stylelint-disable-next-line length-zero-no-unit */
    /*--demo-notice-height: 0px;*/
}

.DemoNotice {
    padding-block-start: env(safe-area-inset-top);
    align-items: center;
    background-color: var(--primary-base-color);
    color: $white;
    display: flex;
    font-size: 14px;
    font-weight: bold;
    height: var(--demo-notice-height);
    justify-content: center;
    position: fixed;
    inset-block-start: 0;
    width: 100%;
    z-index: 110;

    @at-root .isDemoVisible {
        /*--demo-notice-height:40px;*/
    }
}
