/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --table-body-border: 1px solid #e2e2e2;
    --table-thead-bg: #f8f8f8;
}

table {
    thead th {
        background-color: var(--table-thead-bg);
        border-block-end: 1px solid var(--color-gray85);
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
    }

    thead th strong {
        font-weight: 500;
    }

    tbody tr:not(:last-child) {
        border-block-end: var(--table-body-border);
    }

    tr {
        page-break-inside: avoid;
    }

    td,
    th {
        padding: 10px;
        text-align: start;
        min-width: 120px;
        vertical-align: top;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;

        @include mobile {
            min-width: 140px;
            font-size: 12px;
            line-height: 16px;
        }
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: auto;
}

.Tablecommon {
    &-overflowscroll {
        overflow-x: auto;
    }

    table {
        border: 0px;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 1px;
        table-layout: inherit;
        margin-block-end: 0px;

        thead {
            td,
            th {
                background-color: var(--table-thead-bg);
                border-block-end: 1px solid var(--color-gray85);
                color: var(--color-black);
                padding: 12px 15px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
            }
        }

        tbody {
            td {
                background-color: var(--color-white);
                color: var(--color-black);
                padding: 8px 15px;
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                vertical-align: middle;
            }
        }
    }

    @include desktop {
        table {
            background-color: var(--color-gray85);
            
            tbody {
                tr {
                    &:hover {
                        td {
                            background-color: var(--secondary-base-color);
                        }
                    }
                }
            }
        }
    }

    @include mobile {
        margin: 0px auto;
        width: 100%;
        
        table,
        thead,
        tbody,
        thead th,
        tbody td,
        tbody tr {
            display: block;
        }

        thead {
            tr {
                position: absolute;
                inset-block-start: -9999px;
                inset-inline-start: -9999px;
            }
        }

        thead, 
        tbody {
            tr {
                &:not(:last-child){
                    border: 0px solid var(--color-gray85);
                    padding-block-end: 15px;
                }
            }
        }

        table {
            thead,
            tbody {
                td {
                    border: none;
                    border-bottom: 1px solid var(--color-gray85);
                    position: relative;
                    text-align: start;
                    padding: 15px 15px 15px 50%;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }

        tbody {
            td {
                &::before {
                    content: attr(data-th) "";
                    position: absolute;
                    inset-block-start: 15px;
                    inset-inline-start: 15px;
                    width: 45%;
                    text-align: start;
                    white-space: nowrap;
                    text-transform: capitalize;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 14px;
                    line-height: 14px;
                    font-weight: 500;
                }
            }
        }
    }
}
