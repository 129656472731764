/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 .KeyValueTable {
    &-Heading {
        color: var(--color-black);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding-block-end: 10px;
    }
    
    &-Body {
        p {
            color: var(--color-black);
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            
            &:last-child {
                margin-block-end: 0px;
            }
        }
    }
    
    .RadioButtonIcon {
        display: none;
    }
}

/*.KeyValueTable {
    margin: 0;
    margin-block-end: 12px;
    min-width: 100%;
    font-size: 14px;

    @include mobile {
        margin-block-end: 14px;
    }

    &-Heading {
        font-size: 14px;
    }

    &-Item {
        &-Name {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Totals {
        &-Separated {
            border-block-start: 1px solid rgba(10, 10, 10, .5);
        }
    }

    &-Wrapper {
        @include mobile {
            overflow-x: auto;
        }
    }

    .RadioButtonIcon{display:none;}
}*/
