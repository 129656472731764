/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

/* Custom Breakpoints css  Start*/

@mixin desktop-lg-down {
    @media (max-width: 1449px) {
        @content;
    }
}

@mixin desktop-lg-up {
    @media (min-width: 1450px) {
        @content;
    }
}

@mixin desktop-xl-down {
    @media (max-width: 1559px) {
        @content;
    }
}

@mixin desktop-xl-up {
    @media (min-width: 1560px) {
        @content;
    }
}

/* Custom Breakpoints css  End*/

@mixin desktop {
    @media (min-width: 811px) {
        @content;
    }
}

@mixin ultra-narrow-desktop {
    @media (min-width: 810px) and (max-width: 1160px) {
        @content;
    }
}

@mixin narrow-desktop {
    @media (min-width: 1024px) and (max-width: 1280px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 811px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: 811px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (min-width: 811px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 810px) {
        @content;
    }
}

@mixin mobile-small {
    @media screen and (max-width: 575px) {
        @content;
    }
}

@mixin mobile-exsmall {
    @media screen and (max-width: 374px) {
        @content;
    }
}

@mixin standalone {
    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}

@mixin hoverable {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}
