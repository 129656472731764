/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

.ContactPage {
    .ContentWrapper {
        max-width: 1280px;
    }
    &-Heading {
        padding-block-start: 10px;
        padding-block-end: 30px;
        font-size: 32px;
        line-height: 32px;
        text-align: start; 
        font-weight: 600;
        
        @include mobile {
            padding-block-start: 20px;
            padding-block-end: 20px;
            font-size: 20px;
            line-height: 20px;
        }
    }

    &-ColumnWrapper {
        display: flex;
        margin-block-end: 24px;

        @include mobile {
            display: block;
            padding: 0px 0 15px;
        }
    }

    &-Column {
        flex: 1;

        &_isContent {
            margin-inline-end:0px;

            @include mobile {
                margin-inline-end: 0;
            }

            .block-contact {
                padding-block-end: 25px;

                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    padding-block-end: 3px;
                }

                .block-content {
                    padding-inline-start: 0px;
                }

                p {
                    line-height: 24px;
                    margin-block-end: 5px;
                }
            }
        }
    }

    .Form {
        .Button {
            @include mobile {
                width: 100%;
            }
        }
    }

    .FieldForm-Fields {
        margin-block-end: 20px;
    }
}
