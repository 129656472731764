/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

.InstallPrompt {
    // prevent margin merging
    border-block-start: 1px solid transparent;

    @include mobile {
        + .CmsPage {
            margin-block-start: 0;

            .CmsPage-Wrapper {
                margin-block-start: 0;
            }
        }
    }
}
