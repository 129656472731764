$Montserrat-font-path: "../fonts/Montserrat";

@mixin font-common-properties {
    font-family: "Montserrat";
    font-style: normal;
    font-display: optional;
}

/** Montserrat Light **/
@font-face {
    @include font-common-properties();

    font-weight: 300; 
    src: url($Montserrat-font-path + "/Montserrat-Light.woff2") format("woff2"),
        url($Montserrat-font-path + "/Montserrat-Light.woff") format("woff");
}

/** Montserrat Regular **/

@font-face {
    @include font-common-properties();
    
    font-weight: 400;
    src: url($Montserrat-font-path + "/Montserrat-Regular.woff2") format("woff2"),
        url($Montserrat-font-path + "/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Medium **/

@font-face {
    @include font-common-properties();

    font-weight: 500;
    src: url($Montserrat-font-path + "/Montserrat-Medium.woff2") format("woff2"),
        url($Montserrat-font-path + "/Montserrat-Medium.woff") format("woff");
}

/** Montserrat SemiBold **/

@font-face {
    @include font-common-properties();

    font-weight: 600;
    src: url($Montserrat-font-path + "/Montserrat-SemiBold.woff2") format("woff2"),
        url($Montserrat-font-path + "/Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat Bold **/

@font-face {
    @include font-common-properties();

    font-weight: 700;
    src: url($Montserrat-font-path + "/Montserrat-Bold.woff2") format("woff2"),
        url($Montserrat-font-path + "/Montserrat-Bold.woff") format("woff");
}

/** Montserrat ExtraBold **/

@font-face {
    @include font-common-properties();

    font-weight: 800;
    src: url($Montserrat-font-path + "/Montserrat-ExtraBold.woff2") format("woff2"),
        url($Montserrat-font-path + "/Montserrat-ExtraBold.woff") format("woff");
}

/** Montserrat Black **/

@font-face {
    @include font-common-properties();

    font-weight: 900;
    src: url($Montserrat-font-path + "/Montserrat-Black.woff2") format("woff2"),
        url($Montserrat-font-path + "/Montserrat-Black.woff") format("woff");
}
