/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

::placeholder {
    overflow: visible;
}

.SearchField {
    display: none;
    margin: 0px auto;
    max-width: 100%;

    @include desktop {
        display: block;
        width: 100%;
        max-width: 725px;
        grid-column: 2 / 3;
        padding-inline: 10px;
        
        @include tablet {
            padding-inline: 15px;
        }

        @include narrow-desktop {
            padding-inline: 25px;
        }

        @include wide-desktop {
            padding-inline: 30px;
        }
    }

    &_isVisible {
        display: block;

        @include mobile {
            margin-block-start: 16px;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        height: 24px;
        inset-inline-end: 15px;
        inset-block-start: calc(50% - 12px);
        width: 24px;
    }

    &-CloseIcon {
        inset-inline-end: 8px;
    }

    &-Input {
        width: 100%;
        height: var(--header-logo-height);
        font-size: 14px;
        border:2px solid var(--color-orange);
        min-height: 50px;

        &:focus {
            border-color:var(--color-orange);
        }
    }
}

@include mobile {
    .Searchfield {
        &-Mobile {
            &-Open {
                display: block;
                position: fixed;
                inset-block-start: 0px;
                inset-block-end: 0px;
                inset-inline-start: 0px;
                inset-inline-end: 0px;
                overflow: auto;
                background: var(--color-white);
                z-index: 1;
                padding: 15px;
                
                .SearchField-Input {
                    height: 50px !important;
                }
            }
        }
    }
    
    .SearchOverlay:not(.Overlay_isStatic) {
        /*position:fixed;width:100%;overflow:scroll;overflow-x:hidden;max-width:100%;*/
        pointer-events: auto;
        inset-block-start: 70px;
        height: calc(100% - 70px);
        opacity: unset;
        z-index: 999;
        
        &.SearchOverlay-Mobile-Hide {
            display: none;
        }
    }

    .SearchOverlay-Results {
        display: block;
        padding: 0px 15px;
    }

    .SearchItem {
        border-bottom: 1px solid var(--color-gray85);
    }
}
