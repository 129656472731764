/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --default-button-padding: 20px;
    --default-button-height: 50px;
    --button-border-radius: 5px;

    // button
    --button-background: var(
        --imported_buttons_background_color,
        var(--primary-base-color)
    );
    --button-border: var(
        --imported_buttons_border_color,
        var(--primary-base-color)
    );
    --button-color: var(--imported_buttons_color, #{$white});
    --button-height: var(
        --imported_buttons_height,
        var(--default-button-height)
    );
    --button-padding: var(
        --imported_buttons_padding,
        var(--default-button-padding)
    );

    // button (Hover)
    --button-hover-background: var(
        --imported_hoverButtons_background_color,
        var(--primary-dark-color)
    );
    --button-hover-border: var(
        --imported_hoverButtons_border_color,
        var(--primary-dark-color)
    );
    --button-hover-color: var(--imported_hoverButtons_color, #{$white});

    // Secondary button (Hollow)
    --hollow-button-background: var(--color-white);
    --hollow-button-color: var(
        --imported_hollowButtons_color,
        var(--primary-base-color)
    );
    // Secondary button (Hover)
    --hollow-button-hover-background: var(--color-white);
    --hollow-button-hover-border: var(
        --imported_hollowHoverButtons_border_color,
        var(--primary-dark-color)
    );
    --hollow-button-hover-color: var(
        --imported_hollowHoverButtons_color,
        var(--primary-dark-color)
    );

    // Grey (Grey)
    --grey-button-background: var(--grey-base-color);
    --grey-button-border: var(--grey-base-color);

    // Grey (Hover)
    --grey-button-hover-background: var(--grey-dark-color);
    --grey-button-hover-border: var(--grey-dark-color);

    // Grey Border
    --greybb-button-background: var(--color-white);
    --greybb-button-color: var(--grey-base-color);
    // Grey Border  (Hover)
    --greybb-button-hover-background: var(--color-white);
    --greybb-button-hover-color: var(--color-orange);
    --greybb-button-hover-border: var(--color-orange);

    // Taupe Grey
    --taupe-grey-button-background: var(--taupe-grey-base-color);
    --taupe-grey-button-border: var(--taupe-grey-base-color);
    --taupe-grey-button-color: var(--color-white);
    // Taupe Grey ( Hover)
    --taupe-grey-button-hover-background: var(--taupe-grey-dark-color);
    --taupe-grey-button-hover-border: var(--taupe-grey-dark-color);
    --taupe-grey-button-hover-color: var(--color-white);
}

.Button {
    @include button;
}

button {
    background-color: transparent;

    // override default blue color for lists in Safari since iOS15
    
    & * {
        color: var(--color-black);
    }
}
