/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.PaginationLink {
    border-radius: 4px;
    background-color: var(--color-white);
    color: var(--color-black);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &_isArrow {
        &:hover {
            .svg-pagination-arrow {
                stroke: var(--color-white);
            }
        }
    }
    
    &_isCurrent,
    &:hover {
        background-color: var(--color-orange);
        color: var(--color-white);
    }
}
