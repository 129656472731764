/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --popup-background: #0003;

    @include mobile {
        --popup-content-padding: 15px;
    }

    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-content-padding: 12px;
    --popup-max-height: 80%;

    @include desktop {
        --popup-min-width: 490px;
        --popup-content-padding: 20px;
    }
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 400;
    position: fixed;
    background-color: var(--popup-background);
    inset-inline-start: 0;
    inset-block-start: var(--header-total-height);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        align-items: stretch;
    }

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;
        inset-block-start: 0;

        @include mobile {
            inset-block-start: var(--header-total-height);
            height: calc(100% - var(--header-total-height));
        }
        
        &.ShareWishlistPopup {
            .Form {
                
                .FieldForm-Fields {
                    margin-bottom: 15px;
                }
            }
        }
    }

    &-Heading {
        text-decoration: none;
        text-transform: none;
        font-weight: 500;
        margin: 0;
        font-size: 18px;
        line-height: 24px;
    }
    
    &-Header {
        position: relative;
        border-bottom: 1px solid var(--color-gray85);
        padding-block-end: 10px;
        margin-block-end: 15px;
        padding-inline-end: 20px;
    }

    &-Content {
        background-color: var(--popup-content-background);
        border-radius: 5px;
        padding: var(--popup-content-padding);
        padding-block-end: 0;
        min-width: var(--popup-min-width);
        max-width: 100%;
        overflow-x: hidden;
        max-height: var(--popup-max-height);
        overflow-y: auto;

        &::after {
            content: "";
            display: block;
            padding-block-end: var(--popup-content-padding);
        }

        @include mobile {
            border-radius: 0;
            max-height: 100%;
        }

        .new-address {
            text-align: end;
            padding-block-start: 15px;

            button {
                text-transform: none;
                font-weight: 600;
            }
        }
    }

    & &-CloseBtn {
        position: absolute;
        inset-block-start: 0px;
        inset-inline-end: 0px;
        z-index: 5;
        
        .CloseIcon {
            height: 13px;
            width: 13px;
            float: inline-start;
        }
    }

    .Loader {
        @include mobile {
            position: fixed;
        }
    }

    .NotificationList {
        width: 100vw;
        inset-block-start: 0;
        inset-inline-start: calc(0px - var(--popup-content-padding));
        position: relative;
    }
}

.scrollDisabled {
    margin-block-start: 0;
    width: 100%;

    @include desktop {
        position: fixed;
        overflow-y: scroll;
    }

    @include mobile {
        overflow: hidden;
    }
}
