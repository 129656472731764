@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -moz-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

img {
    height: auto;
}

.tal {
    text-align: start;
}

.tar {
    text-align: end;
}

.tac {
    text-align: center;
}

.ovr_auto {
    overflow: auto;
}

.ovr_hidden {
    overflow: hidden;
}

.pos {
    position: static;
}

.por {
    position: relative;
}

.poa {
    position: absolute;
}

.pof {
    position: fixed;
}

.f14{font-size:13px!important;}

.mt-10{ margin-top:25px;}

.dblock{ display:block;}

.Basket {
    &-Heading {
        background-color: #f2f2f2;
        padding: 15px 20px;
        border-radius: 4px 4px 0px 0px;
        border-block-end: 1px solid #c4c4c4;
        display: flex;
        justify-content: space-between;

        @include mobile-small {
            display: inherit;
            justify-content: inherit;
        }
    }

    &-Left {
        position: relative;
        padding-inline-start: 48px;

        h3 {
            color: var(--color-orange);
            margin: 0px;
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;

            @include tablet {
                font-size: 18px;
                line-height: 22px;
            }

            @include mobile {
                font-size: 16px;
                line-height: 20px;
            }
        }

        p {
            color: var(--color-black);
            margin-block-end: 0px;
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
        }
    }

    &-icon {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        inset-block-start: 0px;
        inset-inline-start: 0px;

        svg {
            fill: var(--color-black);
        }
    }

    &-Right {
        .link-black {
            font-size: 12px;
            line-height: 15px;
            font-weight: 400;
            white-space: nowrap;
        }

        button {
            &.link-black {
                text-decoration: underline;
                cursor: pointer;

                &:hover:not([disabled]) {
                    text-decoration: none;
                    color: var(--color-orange);
                }

                &:disabled {
                    cursor: auto;
                }
            }
        }

        @include mobile-small {
            padding-inline-start: 48px;

            .link-black {
                display: inline-block;
                margin-block-start: 5px;
                white-space: normal;
                text-align: left;
            }
        }
    }
}

// Top Sellers

.top-sellers {
    .Button_grey_border {
        text-transform: none;
        width: auto;
        min-width: 223px;
    }

    h2 {
        margin-block: 0px;
        text-align: center;
    }

    .ProductCard {
        &:last-child {
            margin-block-end: 0px;
        }
    }

    .slick-arrow-outside>div[searchspring-recommend="home"] {
        display: none;
    }

    .slick-arrow-outside>div[searchspring-recommend="home"]:nth-last-child(2) {
        display: block;
        font-weight: 700;
    }

    .slick-arrow-outside>div[searchspring-recommend="404"] {
        display: none;
    }

    .slick-arrow-outside>div[searchspring-recommend="404"]:nth-last-child(2) {
        display: block;
        font-weight: 700;
    }

    .slick-arrow-outside>div[searchspring-recommend="similar"] {
        display: none;
    }

    .slick-arrow-outside>div[searchspring-recommend="similar"]:nth-last-child(2) {
        display: block;
        font-weight: 700;
    }

    .slick-arrow-outside>div[searchspring-recommend="cross-sell"] {
        display: none;
    }

    .slick-arrow-outside>div[searchspring-recommend="cross-sell"]:nth-last-child(2) {
        display: block;
        font-weight: 700;
    }

    @include desktop {
        .HomePage-space {
            padding-block-start: 50px;
        }

        .slick-list {
            padding-block-start: 20px;
            padding-block-end: 25px;
        }

        .slick-space-5 {
            .slick-list {
                margin-inline: -12px;

                .slick-slide {
                    padding-inline: 12px;
                }
            }
        }

    }

    @include mobile {
        .HomePage-space {
            padding-block: 30px;
        }

        h2 {
            margin-block-end: 20px;
        }

        .ProductCard {}

        .slick {
            &-dots {
                margin-block-start: 4px;
                margin-block-end: 12px;

                li {
                    line-height: 5px;

                    button {
                        border: 0px;
                        width: 5px;
                        height: 5px;
                        background-color: #d9d9d9;
                    }

                    &.slick-active {
                        button {
                            background-color: var(--color-orange);
                        }
                    }
                }
            }
        }

        .ProductPrice {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            min-height: 36px;

            &-Price {
                padding-inline-end: 0px;
            }

            del {
                padding-inline-end: 0px;
            }

            &-SavePrice {
                width: 100%;
                padding-block-start: 5px;
            }
        }
    }
}

.ss-top-sellers {
    .ContentWrapper {
        &.slick-arrow-outside {
            padding-inline: 0px;
            max-width: 100%;
        }
    }

    .ss__searchspring__recommendations {
        margin: 0px auto;
        padding: 0px;
        max-width: 1480px;

        @include desktop-lg-up {
            max-width: 1512px;
        }

        @include desktop-xl-up {
            max-width: 1560px;
        }
    }

    .ss__carousel {
        padding-inline: 30px;
        max-width: 1480px;

        @include desktop-lg-up {
            padding-inline: 46px;
            max-width: 1512px;
        }

        @include desktop-xl-up {
            padding-inline: 70px;
            max-width: 1560px;
        }

        @include mobile-exsmall {
            padding-inline: 15px;
        }

        .swiper-wrapper {
            @include desktop {
                padding-block-start: 20px;
                padding-block-end: 25px;
            }
        }

        .swiper-slide {
            @include desktop {
                padding-inline: 12px;
            }

            @include mobile {
                padding-inline: 5px;
            }
        }

        .ss__carousel__prev-wrapper,
        .ss__carousel__next-wrapper {
            position: absolute;
            inset-block-start: 50%;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            cursor: pointer;
            z-index: 99;
            border: none;
            outline: none;

            >div {
                width: 30px;
                height: 50px;
                padding: 0px;

                &::before {
                    content: "";
                    width: 13px;
                    height: 13px;
                    border-bottom: 2px solid var(--slick-arrow-color);
                    border-left: 2px solid var(--slick-arrow-color);
                    position: absolute;
                    inset-block-start: 19px;
                }
            }

            .ss-1v72vbw {
                display: none;
            }
        }

        .ss__carousel__prev-wrapper {
            inset-inline-start: 0px;

            >div {
                &::before {
                    @include rotate(45deg);
                    inset-inline-start: 12px;
                }
            }

            [dir="rtl"] & {
                inset-inline-start: auto;

                >div {
                    &::before {
                        @include rotate(-135deg);
                        inset-inline-start: 6px;
                    }
                }
            }
        }

        .ss__carousel__next-wrapper {
            inset-inline-end: 0px;

            >div {
                &::before {
                    @include rotate(-135deg);
                    inset-inline-start: 6px;
                }
            }

            [dir="rtl"] & {
                inset-inline-end: auto;

                >div {
                    &::before {
                        @include rotate(45deg);
                        inset-inline-start: 12px;
                    }
                }
            }
        }
    }

    .ss__searchspring__recommendations .ss__result {
        max-width: none;
        padding-inline: 0px;
        margin: 0px;
        padding-block: 0px;
    }

    .ss__result {
        padding: 0px;
        max-width: none;
    }
}

.Common {
    &-Hd {
        font-size: 36px;
        line-height: 36px;
        font-weight: 500;
        padding-block-start: 30px;

        @include mobile {
            font-size: 20px;
            line-height: 20px;
            padding-block: 30px;
        }
    }

    &-Row {
        padding-block-start: 30px;

        @include desktop {
            display: grid;
            grid-template-columns: 1fr 300px;
            grid-column-gap: 15px;
            align-items: self-start;
        }

        @include wide-desktop {
            grid-template-columns: 1fr 455px;
            grid-column-gap: 30px;
        }

        @include narrow-desktop {
            grid-template-columns: 1fr 355px;
            grid-column-gap: 20px;
        }

        @include mobile {
            padding: 0;
        }
    }
}

.Back {
    &-Link-button {
        display: flex;
        align-items: center;
        color: var(--color-black);
        cursor: pointer;
        font-weight: 500;

        .svg-back-left {
            margin-inline-end: 10px;
            fill: var(--color-black);
        }

        &:hover {
            color: var(--color-orange);

            .svg-back-left {
                fill: var(--color-orange);
            }
        }
    }
}

.amrma {
    &-title {
        margin-block-start: 0px;
        font-size: 22px;
        line-height: 30px;
        font-weight: 500;
        margin-block-end: 15px;
    }
}

.thumb-wrapper {
    position: relative;
    padding-block-end: 100%;
    display: block;
    overflow: hidden;

    img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%) translateY(-50%);
        object-fit: contain;
    }
}

.Category-Banner.fxheight{min-height:350px;}
.top-cat-man.fxheight{min-height:325px;}
.top-sellers-man.fxheight{min-height:640px;}
.new-in-man.fxheight{min-height:640px;}
