/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.AmastyNewReturn {
    .Form {
        margin-block-start: 20px;
    }

    ._strong {
        font-weight: 600;
    }

    ._nomargin {
        margin: 0;
    }

    .label {
        font-size: 14px;
        font-weight: bold;
        line-height: 19px;
    }

    .amrma {
        &-text {
            font-size: 1.3rem;
        }

        &-header {
            border-bottom: 1px solid var(--color-gray85);
            padding-block-end: 10px;
            font-size: 24px;
            line-height: 30px;
        }

        &-customer {
            &-information {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin-block-end: 40px;
            }

            &-name,
            &-address {
                padding: 15px;
                display: flex;
                flex-direction: column;
                justify-content: start;
                border: 1px solid var(--color-gray85);
            }

            &-address {
                margin-inline-start: -1px;
            }

            @include mobile {
                &-information {
                    grid-template-columns: 1fr;
                }

                &-address {
                    margin-inline-start: 0px;
                    margin-block-start: -1px;
                }
            }
        }

        &-product {
            display: flex;

            &-information {
                align-self: baseline;

                strong {
                    font-weight: 700;
                }

                .amrma-moreinfo {
                    .CartItem-Options {
                        padding-block-start: 10px;

                        li {
                            font-size: 13px;
                            line-height: 13px;
                            margin-block-end: 7px;
                            color: var(--color-dark-charcoal);
                        }
                    }
                }
            }

            &-item {
                display: flex;
                justify-content: space-between;
                padding: 15px 0;
                border-bottom: 1px solid #d1d1d1;
            }

            &-message {
                width: 360px;
                margin-inline-start: 15px;
            }

            @include mobile {
                &-item {
                    flex-direction: column;
                }

                &-message {
                    width: 100%;
                    margin-inline-start: 0;
                    margin-block-start: 15px;
                }
            }
        }

        &-products {
            &-table {
                margin-block-end: 40px;
            }
        }

        &-checkbox {
            &-container {
                display: flex;
                position: relative;

                .amrma {
                    &-label {
                        position: absolute;
                        inset-block-start: 44px;
                        width: 13px;
                        height: 13px;
                        box-sizing: border-box;
                        border: 1px solid #ccc;
                        border-radius: 3px;
                        background: #fff;
                        cursor: pointer;
                    }

                    &-image {
                        cursor: pointer;
                        margin-inline-end: 20px;
                        padding-inline-start: 0px;
                    }
                }

                &.-policy {
                    align-items: baseline;
                }

                input.amrma-checkbox:disabled + .input-control {
                    opacity: 0;
                    visibility: hidden;
                    display: none;
                }
            }
        }

        &-image {
            display: block;
            margin-inline-end: 20px;
            padding-inline-start: 33px;
            width: 100px;
            height: 100px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        &-item {
            &-name {
                font-size: 1.4rem;
            }
        }

        &-message {
            &-container {
                margin: 10px 20px 20px;
                border-radius: 2px;
                background: #eaf1ff;

                &.-gray {
                    margin: 0;
                    box-sizing: border-box;
                    padding: 15px;
                    border: 2px solid #ccc;
                    border-radius: 2px;
                    background: none;
                }

                &.-options {
                    margin: 0;
                    box-sizing: border-box;
                    border: 2px solid rgba(23, 93, 224, 0.4);
                    border-radius: 2px;
                    box-shadow: 0 4px 8px rgba(0, 62, 176, 0.1);
                    background: none;
                }
            }
        }

        &-bank-details,
        &-return-instructions,
        &-header-block {
            margin-block-end: 40px;
        }

        &-return {
            &-qty {
                display: flex;
                align-items: center;
                margin: 20px 0 20px 20px;

                .amrma {
                    &-label {
                        margin-inline-end: 10px;
                        font-weight: bold;
                        font-size: 1.3rem;
                    }

                    &-total {
                        font-size: 1.5rem;
                        margin-inline-start: 10px;
                    }

                    &-qty {
                        &-select {
                            display: flex;
                            align-items: baseline;
                        }
                    }

                    .Field {
                        margin: 0;
                    }
                }
            }

            &-policy {
                display: inline-block;
                margin-block-start: 10px;

                &-required {
                    color: #e02b27;
                }
            }

            &-select {
                margin-inline: 20px;
                margin-block-end: 20px;

                .amrma {
                    &-title {
                        margin-block-start: 10px;
                    }
                }

                .Field {
                    margin-block-start: 0;
                }
            }
        }

        &-returns {
            &-container {
                display: flex;

                .amrma {
                    &-return {
                        &-link {
                            margin-inline-end: 10px;
                        }
                    }
                }
            }
        }

        &-img {
            &-text {
                margin-block-end: 10px;
                padding: 5px 10px;
                border-radius: 2px;
                background: #f5f5f5;
            }
        }

        &-comment {
            &-block {
                .amrma {
                    &-comment {
                        font-size: 14px;
                        width: 100%;
                    }
                }
            }
        }

        &-attach {
            &-file {
                .amrma {
                    &-attach {
                        display: none;
                    }

                    &-label {
                        margin: 10px;
                        position: relative;
                        padding-inline-start: 20px;
                        font-weight: bold;
                        font-size: 16px;
                        cursor: pointer;
                        color: #1979c3;
                        transition: color 0.3s ease;

                        &::before {
                            position: absolute;
                            inset-block-start: 4px;
                            inset-inline-start: 0;
                            width: 17px;
                            height: 16px;
                            content: "";
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NiAxNmEzLjMzMiAzLjMzMiAwIDAgMS0yLjM5My0xLjAzNUEzLjU2NSAzLjU2NSAwIDAgMSAwIDEyLjQ5MmEzLjMxOCAzLjMxOCAwIDAgMSAuOTIyLTIuMzE4bC4xNC0uMTQ3TDkuNTU4IDEuMjVBNC4wNzkgNC4wNzkgMCAwIDEgMTIuNTExIDBjMS4xMzYtLjAwMSAyLjI4Ny40NjMgMy4xNjUgMS4zN0E0LjcwNyA0LjcwNyAwIDAgMSAxNyA0LjYzNmE0LjM2NCA0LjM2NCAwIDAgMS0xLjIxIDMuMDVsLTcuMTMyIDcuMzctLjUzMS41NDgtMS4wNi0xLjA5N0wxNC43MyA2LjU5Yy41MTMtLjUzLjc2OC0xLjIyNi43Ny0xLjk1M2EzLjEzIDMuMTMgMCAwIDAtLjg4Ni0yLjE3MSAyLjkzMiAyLjkzMiAwIDAgMC0yLjEwMy0uOTE1IDIuNTk5IDIuNTk5IDAgMCAwLTEuODkuNzk1TDEuOTg0IDExLjI3Yy0uMzIyLjMzMi0uNDguNzYzLS40ODIgMS4yMjIgMCAuNDg1LjE4My45ODYuNTYyIDEuMzc3LjM3OS4zOS44NjIuNTggMS4zMzIuNThhMS42MzUgMS42MzUgMCAwIDAgMS4xODQtLjQ5Nmw2LjUxNC02LjczYS42NTUuNjU1IDAgMCAwIC4xODItLjQ3LjguOCAwIDAgMC0uMjI5LS41NTYuNzQ1Ljc0NSAwIDAgMC0uNTM4LS4yMzYuNjIuNjIgMCAwIDAtLjQ1My4xODhsLTUuNTQgNS43MjQtMS4wNjItMS4wOTYgNS41NC01LjcyNGMuNDE2LS40MzIuOTctLjY0NiAxLjUxNS0uNjQ0LjU3OCAwIDEuMTYuMjM2IDEuNi42OTEuNDQuNDU2LjY3IDEuMDU2LjY3IDEuNjU0IDAgLjU2LS4yMDcgMS4xMzQtLjYyMyAxLjU2NEw1LjY0IDE1LjA0OUEzLjEwNCAzLjEwNCAwIDAgMSAzLjQwMiAxNmgtLjAwNnoiIGZpbGw9IiMxOTc5QzMiLz48L3N2Zz4=");
                        }

                        &:hover {
                            text-decoration: underline;
                            color: #2196f3;
                        }
                    }

                    &-file {
                        margin: 5px 0;
                        padding-inline-start: 20px;
                        color: #1979c3;
                    }
                }
            }
        }

        &-chat {
            &-file {
                position: relative;
                padding-inline-start: 25px;

                &::before {
                    position: absolute;
                    inset-inline-start: 0;
                    width: 17px;
                    height: 16px;
                    content: "";
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NiAxNmEzLjMzMiAzLjMzMiAwIDAgMS0yLjM5My0xLjAzNUEzLjU2NSAzLjU2NSAwIDAgMSAwIDEyLjQ5MmEzLjMxOCAzLjMxOCAwIDAgMSAuOTIyLTIuMzE4bC4xNC0uMTQ3TDkuNTU4IDEuMjVBNC4wNzkgNC4wNzkgMCAwIDEgMTIuNTExIDBjMS4xMzYtLjAwMSAyLjI4Ny40NjMgMy4xNjUgMS4zN0E0LjcwNyA0LjcwNyAwIDAgMSAxNyA0LjYzNmE0LjM2NCA0LjM2NCAwIDAgMS0xLjIxIDMuMDVsLTcuMTMyIDcuMzctLjUzMS41NDgtMS4wNi0xLjA5N0wxNC43MyA2LjU5Yy41MTMtLjUzLjc2OC0xLjIyNi43Ny0xLjk1M2EzLjEzIDMuMTMgMCAwIDAtLjg4Ni0yLjE3MSAyLjkzMiAyLjkzMiAwIDAgMC0yLjEwMy0uOTE1IDIuNTk5IDIuNTk5IDAgMCAwLTEuODkuNzk1TDEuOTg0IDExLjI3Yy0uMzIyLjMzMi0uNDguNzYzLS40ODIgMS4yMjIgMCAuNDg1LjE4My45ODYuNTYyIDEuMzc3LjM3OS4zOS44NjIuNTggMS4zMzIuNThhMS42MzUgMS42MzUgMCAwIDAgMS4xODQtLjQ5Nmw2LjUxNC02LjczYS42NTUuNjU1IDAgMCAwIC4xODItLjQ3LjguOCAwIDAgMC0uMjI5LS41NTYuNzQ1Ljc0NSAwIDAgMC0uNTM4LS4yMzYuNjIuNjIgMCAwIDAtLjQ1My4xODhsLTUuNTQgNS43MjQtMS4wNjItMS4wOTYgNS41NC01LjcyNGMuNDE2LS40MzIuOTctLjY0NiAxLjUxNS0uNjQ0LjU3OCAwIDEuMTYuMjM2IDEuNi42OTEuNDQuNDU2LjY3IDEuMDU2LjY3IDEuNjU0IDAgLjU2LS4yMDcgMS4xMzQtLjYyMyAxLjU2NEw1LjY0IDE1LjA0OUEzLjEwNCAzLjEwNCAwIDAgMSAzLjQwMiAxNmgtLjAwNnoiIGZpbGw9IiMxOTc5QzMiLz48L3N2Zz4=");
                }

                .amrma {
                    &-file {
                        font-weight: 600;
                    }
                }

                &.-newreturn {
                    display: flex;
                    align-items: center;
                    margin-inline-start: 30px;

                    &::before {
                        inset-block-start: 7px;
                    }

                    .amrma {
                        &-file {
                            padding: 0;
                        }

                        &-delete {
                            padding: 0;
                            margin-inline-start: 10px;
                        }
                    }
                }

                &.-preview {
                    display: flex;
                    align-items: center;
                    margin: 25px;
                    padding-inline-end: 20px;
                    color: #006bb4;
                }
            }
        }

        &-delete {
            display: inline-block;
            margin-inline-start: 10px;
            width: 16px;
            height: 16px;
            background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzNiAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTM1LjkxOCAyLjcxNEwzMy4yMDMgMCAxOC40MTggMTQuNzg3IDMuNjMzIDAgLjkxOCAyLjcxMyAxNS43MDUgMTcuNS45MiAzMi4yODcgMy42MzMgMzVsMTQuNzg1LTE0Ljc4NiAxNC43ODUgMTQuNzg1IDIuNzExLTIuNzEzTDIxLjEzMSAxNy41IDM1LjkxOCAyLjcxNCIgZmlsbD0iIzAwNmJiNCIvPjwvc3ZnPg==");
            background-repeat: no-repeat;
            cursor: pointer;
        }

        &-input-block {
            .Field-Label {
                font-size: 14px;
                font-weight: bold;
                line-height: 19px;
            }
        }

        &-btns {
            &-block {
                margin-block-start: 20px;

                .amreturn {
                    &-btn {
                        margin-inline-end: 20px;

                        @include mobile {
                            margin-inline-end: 0px;
                            margin-block-end: 15px;
                        }
                    }
                }
            }
        }
    }
}
