/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

:root {
    --print-content-wrapper-width: 1280px;
}

.AmastyViewReturn {
    min-height: 100vh;
    max-width: var(--print-content-wrapper-width);
    margin: auto;
    padding-inline: 20px;

    &-LogoWrapper {
        width: var(--logo-width);
        height: var(--logo-height);
        margin-block: 20px;
        display: block;
    }

    &-Copyright {
        display: block;
        text-align: center;
        width: 100%;
        font-size: 12px;
        padding: 10px;
        background: var(--secondary-base-color);
        margin-block-start: 30px;
    }

    &Body {
        .NotificationList,
        .DemoNotice,
        .Header-Wrapper,
        .Breadcrumbs,
        .NavigationTabs {
            display: none;
        }
    }
}


.print{
    &-none{
        .NotificationList,
        .DemoNotice,
        .Header-Wrapper,
        .Breadcrumbs,
        .NavigationTabs,
        .Footer{ display: none;}
    }
}

/*.NotificationList {
    display: none !important;
}

.DemoNotice {
    display: none !important;
}

.Header-Wrapper {
    display: none !important;
}

.Breadcrumbs {
    display: none !important;
}

.NavigationTabs {
    display: none !important;
}

.Footer {
    display: none !important;
}*/

.MyAccountOrderPrint-LogoWrapper {
    width: var(--header-logo-width);
    height: var(--header-logo-height);
    margin-block-start: 20px;
    margin-block-end: 20px;
    display: block;
}
