/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.ForgotPassword {
    .ContentWrapper {
        /*min-height:calc(100vh - var(--header-total-height));*/
        max-width: 1080px;
        padding-block: 30px;
        
        @include desktop() {
            padding-block: 50px;
        }
    }

    &-CreateAccountButton,
    &-SignInButton {
        width: 100%;
    }

    &-Input {
        &_type_email {
            width: 100%;
        }
    }

    &-InnerWrapper {
        @include desktop() {
            display: grid;
            justify-content: space-around;
            margin: 0 auto;
            padding-block-start: 0;
            grid-template-columns: 1fr 1fr;
            grid-gap: 50px;
        }

        h3 {
            border-bottom: 1px solid var(--color-gray85);
            margin: 0;
            font-size: 18px;
            line-height: 22px;
            padding-block-end: 10px;
            font-weight: 500;
            margin-block-end: 15px;
        }

        .MyAccountOverlay {
            &-Additional {
                display: none;
            }
        }

        button {
            @include desktop {
                margin-block-start: 20px;
            }
        }

        > div {
            flex: 1;
        }

        p {
            margin-block-end: 0px;
        }
    }

    &-SignInWrapper {
        margin-block-start: 50px;
    }
    
    .Loader {
        margin: 0;
    }
}
