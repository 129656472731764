/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
* ScandiPWA - Progressive Web App for Magento
*
* Copyright © Scandiweb, Inc. All rights reserved.
* See LICENSE for license details.
*
* @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
* @package scandipwa/scandipwa
* @link https://github.com/scandipwa/scandipwa
*/

:root {
    --menu-item-height: 34px;
    --menu-total-height: 34px;
    --menu-titles-height-mob: 107px;
    --menu-contact-height-mob: 107px;
}

@mixin border-radius($top-left: 10px,
$top-right: null,
$bottom-right: null,
$bottom-left: null) {
    -webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
    -moz-border-radius: $top-left $top-right $bottom-right $bottom-left;
    -ms-border-radius: $top-left $top-right $bottom-right $bottom-left;
    border-radius: $top-left $top-right $bottom-right $bottom-left;
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin transition($x) {
    transition: $x;
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
}

.Menu {
    @include desktop {
        &-Wrapper {
            max-width: 100% !important;
        }

        &-Scrollbar {
            border-block-end: 1px solid var(--primary-divider-color);
        }

        &-Inner {
            margin: 0px auto;
            max-width: var(--content-wrapper-width);
            padding-inline: 15px;
        }

        &-Row {
            display: flex;
            padding-block: 25px;

            &-Noimg{
                .Menu-Col-right{ display:none;}
                .Menu-SubItem{
                    grid-template-columns:repeat(5, 1fr);
                }
            }
        }

        &-Col-left {
            flex: 1;
        }

        &-Col-right {
            display: none;
        }

        &-List {
            padding-inline: 15px;
            margin: 0 auto;
            max-width: var(--content-wrapper-width);

            ul {
                display: flex;
                justify-content: space-between;

                li {
                    height: var(--menu-item-height);

                    .Menu-Link {
                        color: var(--color-black);
                        position: relative;
                        display: inline-block;

                        &::before {
                            margin: 0 auto;
                            content: "";
                            visibility: hidden;
                            width: 0;
                            height: 2px;
                            border-bottom: solid 2px var(--color-orange);
                            position: absolute;
                            inset-inline-start: 0;
                            inset-inline-end: 0;
                            inset-block-end: 0;
                            z-index: 1;
                        }

                        &_isHovered {
                            &::before {
                                visibility: visible;
                                width: 100%;
                            }
                        }

                        figcaption {
                            padding-block-end: 10px;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 24px;
                            text-align: center;
                            display: block !important;

                            .Image {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &-SubItem {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            row-gap: 30px;
            grid-column-gap: 15px;
        }

        &-ItemList {
            >li {
                padding-block: 3px;
                line-height: 0;

                >a {
                    font-size: 12px;
                    line-height: 14px;
                    color: var(--color-black);
                    display: inline-block;

                    &:hover {
                        color: var(--color-orange);
                    }
                }
            }
        }

        &-Sub {
            border-block-start: 1px solid var(--primary-divider-color);
        }

        &-box {
            display: flex;

            &-thumb {
                display: none;
            }

            &-link {
                flex: 1;
            }

            strong {
                display: block;
                padding-block-end: 10px;
                line-height: 0px;

                a {
                    font-size: 12px;
                    line-height: 12px;
                    color: var(--color-black);
                    font-weight: 600;
                }
            }
        }

        &-View-all {
            padding-block-start: 20px;

            .Menu-Link {
                border-bottom: 1px solid var(--color-black);
                display: inline-block;
                color: var(--color-black);
                font-size: 14px;
                line-height: 20px;

                &:hover {
                    border-bottom-color: var(--color-orange);
                    color: var(--color-orange);
                }
            }
        }
    }

    @include narrow-desktop {
        &-Row {
            padding-block: 35px;
        }

        &-List {
            ul {
                li {
                    .Menu-Link {
                        figcaption {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        &-box {
            position: relative;

            &-thumb {
                width: 50px;
                margin-inline-end: 10px;
                display: inherit;
                position: absolute;
                inset-inline-start: 0px;
                inset-block-start: 0px;
            }

            &-link {
                padding-inline-start: 60px;
                min-height: 50px;
            }
        }
    }

    @include wide-desktop {
        &-Row {
            padding-block: 35px;
        }

        &-Col-right {
            width: 220px;
            margin-inline-start: 10px;
            display: inherit;
        }

        &-List {
            ul {
                li {
                    .Menu-Link {
                        figcaption {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        &-SubItem {
            row-gap: 30px;
        }

        &-box {
            position: relative;

            &-thumb {
                width: 70px;
                margin-inline-end: 10px;
                display: inherit;
                position: absolute;
                inset-inline-start: 0px;
                inset-block-start: 0px;
                img{aspect-ratio: 1 / 1;}
            }

            &-link {
                padding-inline-start: 80px;
                min-height: 70px;
            }
        }

        &-View-all {
            padding-block-start: 30px;

            .Menu-Link {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    @include mobile {
        position: fixed;
        inset-block-start: 0px;
        inset-block-end: 0px;
        inset-inline-start: 0px;
        inset-inline-end: 0px;
        padding-top: var(--menu-titles-height-mob);
        padding-block-end: 15px;
        height: 100%;
        background-color: var(--color-white);
        z-index: 1;

        &-Wrapper {
            inset-inline-start: 0px;
            inset-block-start: 0px;
            margin-inline-start: -100%;
            height: 100%;
            overflow-y: auto;
            position: fixed;
            width: 100%;
            z-index: 9999;
            background-color: var(--color-white);
            @include transition(400ms ease);
            @include translate(0px, 0px);
            -webkit-transition-property: -webkit-transform, left, right;
            -webkit-backface-visibility: hidden;
        }

        &-Active {
            overflow: hidden;

            .Menu-Wrapper {
                @include translate(100%, 0px);
            }
        }

        &-Back {
            position: absolute;
            padding: 20px 20px;
            inset-inline-start: 0px;
            inset-block-start: 2px;
            z-index: 1;

            svg {
                margin-inline-end: 5px;
                stroke: var(--color-black);
                margin-block-start: 3px;
                float: inline-start;
            }
        }

        &-close {
            position: absolute;
            padding: 20px 20px;
            inset-inline-end: 0px;
            inset-block-start: 3px;
            z-index: 1;

            svg {
                float: inline-start;
            }
        }

        &-Titles {
            height: var(--menu-titles-height-mob);
            padding: 20px;
            background-color: #f2f2f2;
            position: fixed;
            inset-block-start: 0px;
            inset-inline-end: 0px;
            inset-inline-start: 0px;
            border-block-end: 1px solid #878787;
            z-index: 1;

            h3 {
                padding: 0;
                margin: 0;
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
            }

            &-Link {
                display: flex;
                justify-content: space-between;
                padding-block-start: 25px;

                svg {
                    margin-inline-end: 5px;
                }
            }
        }

        &-Scrollbar {
            margin: 0 auto;
            height: 100%;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                width: 4px;
                -webkit-appearance: none;
                position: relative;
                inset-inline-end: -4px;
                background-color: #cccccc;
            }

            &::-webkit-scrollbar-thumb {
                background: #010101;
            }
        }

        &-Contact {
            height: var(--menu-contact-height-mob);
            position: relative;
            inset-inline-start: 0;
            inset-inline-end: 0;
            inset-block-end: 0;
            padding: 20px;
            background-color: var(--color-white);
            z-index: 1;
            display: flex;
            justify-content: space-evenly;
            border-block-start: 1px solid #878787;

            a {
                font-size: 12px;
                text-align: center;
            }

            &-Icon {
                width: 50px;
                height: 50px;
                background-color: #f2f2f2;
                @include border-radius(50%);
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px auto;
            }

            &-Name {
                display: block;
            }
        }

        &-List {
            padding-inline: 15px;

            >ul {
                padding-block: 10px;
            }
        }

        &-Inner {
            display: none;

            &_isVisible {
                display: block;
            }
        }

        &-View-all {
            padding-block-end: 15px;
            text-align: center;
            padding-block-start: 10px;

            .Menu-Link {
                border-bottom: 1px solid var(--color-black);
                display: inline-block;
                color: var(--color-black);
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;

                &:hover {
                    border-bottom-color: var(--color-orange);
                    color: var(--color-orange);
                }
            }
        }

        &-Link {
            color: var(--color-black);
            position: relative;

            @include hoverable {

                &:hover,
                &:active,
                &_isHovered {
                    color: var(--color-orange);
                }
            }
        }

        &-ItemCaption {
            border: 1px solid #d9d9d9;
            padding-block: 5px;
            text-align: start;
            padding-inline-start: 15px;
            padding-inline-end: 80px; color:#000;

            @include border-radius(5px);

            font-size: 14px;
            font-weight: 500;
            position: relative;
            min-height: 72px;
            display: flex;
            align-items: center;
            margin-block: 10px;

            .Image_ratio_square {
                position: absolute;
                inset-inline-end: 5px;
                inset-block-start: 5px;
                padding-block-end: 60px;
                margin-block-end: 0px;
                width: 60px;
            }
        }

        &-ItemList-3 {
            margin-block: 10px;

            a {
                font-size: 14px;
                line-height: 16px;
                color: var(--color-black);
                display: block;
                border: 1px solid #d9d9d9;
                font-weight: 500;
                padding: 5px 15px;
                border-radius: 5px;
                min-height: 72px;
                display: flex;
                align-items: center;

                &:hover {
                    color: var(--color-orange);
                }
            }
        }

        .mnumob-img {
            >a {
                border: 1px solid #d9d9d9;
                padding-block: 5px;
                text-align: start;
                padding-inline-start: 15px;
                padding-inline-end: 80px;

                @include border-radius(5px);

                font-size: 14px;
                font-weight: 500;
                position: relative;
                min-height: 72px;
                display: flex;
                align-items: center;
                margin-block: 10px;

                .Image_ratio_square {
                    position: absolute;
                    inset-inline-end: 5px;
                    inset-block-start: 5px;
                    padding-block-end: 60px;
                    margin-block-end: 0px;
                    width: 60px;
                }
            }
        }
    }
}

