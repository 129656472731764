/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --default-input-padding: 15px 20px 14px;
    --input-background-color: var(--imported_input_background_color, #fff);
    --input-border-color: var(--imported_input_border_color, #c4c4c4);
    --input-border-color-focus: var(--primary-base-color);
    --input-border-radius: var(--imported_input_border_radius, 3px);
    --input-padding: var(
        --imported_input_padding,
        var(--default-input-padding)
    );
    --input-color: var(--imported_input_color, var(--color-black));
    --input-type-number-width: 28px;
}

input,
textarea,
select {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    color: var(--color-black);
    padding: var(--input-padding);
    appearance: none;
    caret-color: var(--primary-base-color);

    &:focus {
        border-color:var(--color-black);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &:not(:first-of-type) {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &:-webkit-autofill {
        /* stylelint-disable-next-line declaration-no-important */
        box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
        /* stylelint-disable-next-line declaration-no-important */
        background-color: var(--input-background-color) !important;
    }
}

input {
    height: 50px;
    font-size: 12px;
    font-weight: 400;
}

textarea {
    width: 300px;

    @include mobile {
        width: 100%;
    }
}

[type="text"] {
    @include mobile {
        width: 100%;
    }
}
