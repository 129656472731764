/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
@mixin button-disabled {
    pointer-events: none;
    opacity: 0.25;
}

@mixin button {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    justify-content: center;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    text-transform: uppercase;
    height: var(--button-height);
    padding-inline: var(--button-padding);
    color: var(--button-color);
    background: var(--button-background);
    border: 1px solid var(--button-border);

    @include mobile {
        user-select: none;
        width: 100%;
    }
    
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:not([disabled]) {
        &:hover {
            @include desktop {
                background: var(--button-hover-background);
                border-color: var(--button-hover-border);
                color: var(--button-hover-color);
            }
        }
    }

    &[disabled] {
        @include button-disabled();
    }

    &_capitalize {
        text-transform: capitalize;
    }

    &_small {
        height: 37px;
        border-width: 1px;
        font-size: 12px;
    }

    &_isHollow {
        background: var(--hollow-button-background);
        color: var(--hollow-button-color);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background: var(--hollow-button-hover-background);
                    border-color: var(--hollow-button-hover-border);
                    color: var(--hollow-button-hover-color);
                }
            }
        }
    }

    &_grey {
        background: var(--grey-button-background);
        border-color: var(--grey-button-border);

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background: var(--grey-button-hover-background);
                    border-color: var(--grey-button-hover-border);
                }
            }
        }

        &_border {
            background: var(--greybb-button-background);
            color: var(--greybb-button-color);

            svg {
                fill: var(--greybb-button-color);
            }

            &:not([disabled]) {
                &:hover {
                    @include desktop {
                        background: var(--greybb-button-hover-background);
                        color: var(--greybb-button-hover-color);
                        border-color: var(--greybb-button-hover-border);
                        
                        svg {
                            fill: var(--greybb-button-hover-color);
                        }
                    }
                }
            }
        }
    }

    &_taupe_grey {
        background: var(--taupe-grey-button-background);
        border-color: var(--taupe-grey-button-border);
        color: var(--taupe-grey-button-color);
        
        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background: var(--taupe-grey-button-hover-background);
                    border-color: var(--taupe-grey-button-hover-border);
                    color: var(--taupe-grey-button-hover-color);
                }
            }
        }
    }

    &_isWithoutBorder {
        border: 0;
    }

    &_shadow {
        background: linear-gradient( 137.27deg, #f68927 19.41%, #e37614 65.49%);
        box-shadow: 0px 4px 4px rgba(14, 53, 191, 0.25);
        border: 0px;

        &:not([disabled]) {
            &:hover {
                @include desktop {
                    background: linear-gradient( 137.27deg, #f89843 19.41%, #e69146 65.49%);
                    box-shadow: 0px 4px 4px rgba(14, 53, 191, 0.25);
                }
            }
        }
    }

    &_likeLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--primary-base-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--primary-base-color);
        --button-padding: 0;
        --button-hover-padding: 0;
        --button-height: min-content;
        --button-hover-height: min-content;

        border: none;
        text-transform: none;
        margin: 0;
        padding: 0;
        font-weight: normal;
        text-align: start;

        &:active {
            text-decoration: underline;
            padding: 0;
        }

        @include hoverable {
            &:hover,
            &:focus {
                text-decoration: underline;
                padding: 0;
            }
        }
    }
}
