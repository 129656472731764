/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.ResetSection {
    padding-inline: 10px;
}

.ResetAttributes {
    @include desktop {
        margin-block-end: 20px;
        padding-inline-start: 5px;
    }

    @include mobile {
        margin: 10px 10px 16px;
        margin-block-end: 0;
    }

    &-AttributeValue {
        margin-block: 10px;
        margin-inline-start: -5px;
        display: flex;
    }

    &-AttributeText {
        inset-inline-start: 10px;
    }

    &-AttributeOption {
        color: var(--color-taupe-gray);
    }

    &-AttributeOption {
        img {
            height: 32px;
            object-fit: cover;
            vertical-align: middle;
            width: 32px;
        }

        .Image-Content {
            position: initial;
        }
    }

    &-CloseIcon {
        .CloseIcon {
            height: 9px;
            width: 9px;
        }
    }

    &-Title {
        font-weight: 500;
        border-bottom: solid 1px #878787;
        padding-block-end: 20px;
        margin-block-end: 10px;

        @include mobile {
            display: none;
        }

        @include desktop {
            margin-block-start: 0;
            text-transform: none;
        }
    }

    &-MobileTitle {
        @include mobile {
            padding-inline-start: 5px;
            font-weight: 600;
        }
    }
}
