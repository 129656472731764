/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * Amasty RMA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.AmastyViewReturn {
    ._strong {
        font-weight: 600;
    }

    .stars-container {
        display: flex;
        flex-direction: row;
        margin-block-end: 10px;
    }

    .star {
        border: none !important;
        align-self: baseline;
        height: 20px;
        cursor: pointer;
        margin-block-start: 0px;
        min-width: inherit;
        min-height: inherit;
    }

    .hollow-star {
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzIDEuMjM2bDIuMTkyIDYuNzQ3YTIgMiAwIDAgMCAxLjkwMiAxLjM4Mmg3LjA5NGwtNS43MzkgNC4xN2EyIDIgMCAwIDAtLjcyNyAyLjIzNmwyLjE5MyA2Ljc0Ni01Ljc0LTQuMTdhMiAyIDAgMCAwLTIuMzUgMGwtNS43NCA0LjE3IDIuMTkyLTYuNzQ2YTIgMiAwIDAgMC0uNzI2LTIuMjM3bC01Ljc0LTQuMTdoNy4wOTVhMiAyIDAgMCAwIDEuOTAyLTEuMzgxTDEzIDEuMjM2eiIgc3Ryb2tlPSIjRkZDMTA3IiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=")
            center no-repeat;
    }

    .full-star {
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzIDEuMjM2bDIuMTkyIDYuNzQ3YTIgMiAwIDAgMCAxLjkwMiAxLjM4Mmg3LjA5NGwtNS43MzkgNC4xN2EyIDIgMCAwIDAtLjcyNyAyLjIzNmwyLjE5MyA2Ljc0Ni01Ljc0LTQuMTdhMiAyIDAgMCAwLTIuMzUgMGwtNS43NCA0LjE3IDIuMTkyLTYuNzQ2YTIgMiAwIDAgMC0uNzI2LTIuMjM3bC01Ljc0LTQuMTdoNy4wOTVhMiAyIDAgMCAwIDEuOTAyLTEuMzgxTDEzIDEuMjM2eiIgZmlsbD0iI0ZGQzEwNyIgc3Ryb2tlPSIjRkZDMTA3IiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=")
            center no-repeat;
    }

    ._nomargin {
        margin: 0;
    }

    .label {
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
    }

    .amrma {
        &-chat {
            &-container {
                box-sizing: border-box;
                background-color: #f9f9fc;
                border: 1px solid var(--color-gray85);
                border-radius: 2px;
            }

            &-block {
                display: flex;
                flex-direction: column;
                overflow-y: hidden;
                min-height: 270px;
                max-height: 270px;
                padding-inline-end: 5px;
                margin: 30px 20px 20px;

                &:hover {
                    overflow-y: scroll;
                    padding-inline-end: 0;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 3px;
                }

                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 4px;
                    background: #c5c5c5;
                }

                @include mobile-small {
                    margin: 25px 15px 15px;
                }
            }

            &-message {
                margin: 5px 11px;
                max-width: 80%;

                .amrma {
                    &-username {
                        margin-block-end: 5px;
                        color: #777;
                    }

                    &-delete-container {
                        text-align: end;

                        .amrma-delete {
                            float: inline-end;
                        }
                    }
                }

                &.-left {
                    align-self: flex-start;

                    .amrma {
                        &-message {
                            &-block {
                                background: #e2f5fe;

                                &::before {
                                    inset-inline-start: -11px;
                                    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwLjUgMjEuNUwuMzI3IDMuMTlDLS41MzUgMS42MzUuOTA0LS4xODggMi42MTUuMjkxTDEwLjUgMi41djE5eiIgZmlsbD0iI0UxRjVGRSIvPjwvc3ZnPg==");
                                }
                            }
                        }
                    }
                }

                &.-default {
                    display: none;
                }

                &.-right {
                    align-self: flex-end;

                    .amrma {
                        &-username {
                            text-align: end;
                        }

                        &-message {
                            &-block {
                                background: #eceeff;

                                &::before {
                                    inset-inline-end: -11px;
                                    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMjIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMjEuNUwxMC4xNzIgMy4xOWMuODYzLTEuNTU0LS41NzYtMy4zNzctMi4yODctMi44OThMMCAyLjV2MTl6IiBmaWxsPSIjRUNFRUZGIi8+PC9zdmc+");
                                }
                            }
                        }
                    }
                }
            }

            &-file {
                position: relative;
                padding-inline-start: 25px;

                &::before {
                    position: absolute;
                    inset-inline-start: 0;
                    width: 17px;
                    height: 16px;
                    content: "";
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NiAxNmEzLjMzMiAzLjMzMiAwIDAgMS0yLjM5My0xLjAzNUEzLjU2NSAzLjU2NSAwIDAgMSAwIDEyLjQ5MmEzLjMxOCAzLjMxOCAwIDAgMSAuOTIyLTIuMzE4bC4xNC0uMTQ3TDkuNTU4IDEuMjVBNC4wNzkgNC4wNzkgMCAwIDEgMTIuNTExIDBjMS4xMzYtLjAwMSAyLjI4Ny40NjMgMy4xNjUgMS4zN0E0LjcwNyA0LjcwNyAwIDAgMSAxNyA0LjYzNmE0LjM2NCA0LjM2NCAwIDAgMS0xLjIxIDMuMDVsLTcuMTMyIDcuMzctLjUzMS41NDgtMS4wNi0xLjA5N0wxNC43MyA2LjU5Yy41MTMtLjUzLjc2OC0xLjIyNi43Ny0xLjk1M2EzLjEzIDMuMTMgMCAwIDAtLjg4Ni0yLjE3MSAyLjkzMiAyLjkzMiAwIDAgMC0yLjEwMy0uOTE1IDIuNTk5IDIuNTk5IDAgMCAwLTEuODkuNzk1TDEuOTg0IDExLjI3Yy0uMzIyLjMzMi0uNDguNzYzLS40ODIgMS4yMjIgMCAuNDg1LjE4My45ODYuNTYyIDEuMzc3LjM3OS4zOS44NjIuNTggMS4zMzIuNThhMS42MzUgMS42MzUgMCAwIDAgMS4xODQtLjQ5Nmw2LjUxNC02LjczYS42NTUuNjU1IDAgMCAwIC4xODItLjQ3LjguOCAwIDAgMC0uMjI5LS41NTYuNzQ1Ljc0NSAwIDAgMC0uNTM4LS4yMzYuNjIuNjIgMCAwIDAtLjQ1My4xODhsLTUuNTQgNS43MjQtMS4wNjItMS4wOTYgNS41NC01LjcyNGMuNDE2LS40MzIuOTctLjY0NiAxLjUxNS0uNjQ0LjU3OCAwIDEuMTYuMjM2IDEuNi42OTEuNDQuNDU2LjY3IDEuMDU2LjY3IDEuNjU0IDAgLjU2LS4yMDcgMS4xMzQtLjYyMyAxLjU2NEw1LjY0IDE1LjA0OUEzLjEwNCAzLjEwNCAwIDAgMSAzLjQwMiAxNmgtLjAwNnoiIGZpbGw9IiMxOTc5QzMiLz48L3N2Zz4=");
                }

                &.-newreturn {
                    display: flex;
                    align-items: center;
                    margin: 0;

                    &::before {
                        inset-block-start: 7px;
                    }

                    .amrma {
                        &-file {
                            padding: 0;
                        }

                        &-delete {
                            padding: 0;
                            margin-inline-start: 10px;
                        }
                    }
                }

                &.-preview {
                    display: flex;
                    align-items: center;
                    margin: 25px;
                    padding-inline-end: 20px;
                    color: #006bb4;
                }

                .amrma {
                    &-file {
                        font-weight: 600;
                    }
                }
            }

            &-notification {
                display: flex;
                margin: 5px 0 5px 20px;

                .amrma {
                    &-notification {
                        margin-inline-end: 15px;
                        font-weight: 600;
                        font-size: 14px;
                    }

                    &-date {
                        line-height: 19px;
                    }
                }
            }

            &-comment {
                margin: 0 auto;
                padding: 125px 0;
                color: #999;
            }

            &-send {
                position: relative;
                display: flex;
                align-items: center;
                padding: 10px;
                border-radius: 2px;
                background-color: var(--color-white);
                margin: 0 20px 20px;

                .amrma {
                    &-textarea {
                        margin-inline-start: 20px;
                        width: 87%;
                        max-height: 250px;
                        min-height: 40px;
                        border: none;
                        resize: none;
                        font-size: 14px;
                        padding-inline-start: 0px;
                    }

                    &-comment {
                        font-size: 16px;

                        &-block {
                            font-size: 16px;
                        }
                    }

                    &-attach {
                        &-file {
                            width: 29px;
                            height: 33px;
                            margin: 0;

                            .amrma {
                                &-label {
                                    &::before {
                                        inset-block-start: 0;
                                        inset-inline-start: 5px;
                                        width: 29px;
                                        height: 27px;
                                        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01Ljc5MyAyN2MtMS40Ny4wMDItMi45NTMtLjU5Mi00LjA4My0xLjc0N0E1Ljk4MiA1Ljk4MiAwIDAgMSAwIDIxLjA4MWE1LjU2OCA1LjU2OCAwIDAgMSAxLjU3My0zLjkxMmwuMjQtLjI0OEwxNi4zMDQgMi4xMDlBNi45OTYgNi45OTYgMCAwIDEgMjEuMzQyIDBjMS45MzgtLjAwMiAzLjkwMi43OCA1LjM5OSAyLjMxQTcuODk4IDcuODk4IDAgMCAxIDI5IDcuODI1Yy4wMDMgMS44NjgtLjY4MyAzLjczOC0yLjA2NCA1LjE0OUwxNC43NyAyNS40MDlsLS45MDYuOTI1LTEuODEtMS44NTFMMjUuMTI4IDExLjEyYy44NzYtLjg5NSAxLjMxMS0yLjA3IDEuMzEzLTMuMjk3IDAtMS4yOTYtLjUtMi42My0xLjUxLTMuNjYzLTEuMDEzLTEuMDM1LTIuMzE2LTEuNTQzLTMuNTg4LTEuNTQ0YTQuNDU3IDQuNDU3IDAgMCAwLTMuMjI0IDEuMzQzTDMuMzg0IDE5LjAxOGMtLjU0OS41Ni0uODIgMS4yODctLjgyMiAyLjA2My4wMDIuODE4LjMxMyAxLjY2My45NiAyLjMyMy42NDUuNjU3IDEuNDcuOTc4IDIuMjcxLjk4YTIuODA0IDIuODA0IDAgMCAwIDIuMDItLjgzOWwxMS4xMTItMTEuMzU3Yy4yMDgtLjIxMy4zMS0uNDgyLjMxMS0uNzkxIDAtLjMyNi0uMTI0LS42NjYtLjM5MS0uOTRhMS4yNzggMS4yNzggMCAwIDAtLjkxOC0uMzk4IDEuMDYzIDEuMDYzIDAgMCAwLS43NzMuMzE4bC05LjQ1IDkuNjU4LTEuODEyLTEuODQ4IDkuNDUyLTkuNjZhMy41OCAzLjU4IDAgMCAxIDIuNTgzLTEuMDg2Yy45ODYgMCAxLjk3OC4zOTcgMi43MyAxLjE2NmEzLjk5OSAzLjk5OSAwIDAgMSAxLjE0MSAyLjc5IDMuNzcxIDMuNzcxIDAgMCAxLTEuMDYxIDIuNjRMOS42MiAyNS4zOTRBNS4zMjMgNS4zMjMgMCAwIDEgNS44MDMgMjdoLS4wMSIgZmlsbD0iIzE5NzlDMyIvPjwvc3ZnPg==");
                                    }
                                }
                            }
                        }
                    }

                    &-hiddentext {
                        position: absolute;
                        visibility: hidden;
                        opacity: 0;
                        white-space: pre-wrap;
                    }

                    &-send {
                        width: auto;
                    }
                }

                @include mobile-small {
                    margin: 0 15px 15px;
                }
            }
        }

        &-message {
            &-block {
                position: relative;
                display: flex;
                flex-direction: column;
                padding: 15px 20px 10px;
                border-radius: 5px;

                .amrma {
                    &-message {
                        white-space: pre-wrap;
                        word-wrap: break-word;
                    }

                    &-date {
                        align-self: flex-end;
                        margin: 0;
                        padding: 0;
                        color: #999;
                        font-size: 12px;
                    }

                    &-image {
                        width: auto;
                        height: auto;
                        max-width: 225px;
                        margin: 0;
                        padding: 0;
                    }
                }

                &::before {
                    position: absolute;
                    width: 11px;
                    height: 22px;
                    content: "";
                }
            }
        }

        &-block {
            &::before {
                position: absolute;
                inset-block-start: 15px;
                width: 11px;
                height: 22px;
                content: "";
            }
        }

        &-attach {
            &-file {
                .amrma {
                    &-file {
                        margin: 5px 0;
                        padding-inline-start: 20px;
                    }

                    &-label {
                        position: relative;
                        padding-inline-start: 20px;
                        font-weight: bold;
                        font-size: 16px;
                        cursor: pointer;
                        transition: color 0.3s ease;

                        &::before {
                            position: absolute;
                            inset-block-start: 4px;
                            inset-inline-start: 0;
                            width: 17px;
                            height: 16px;
                            content: "";
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjM5NiAxNmEzLjMzMiAzLjMzMiAwIDAgMS0yLjM5My0xLjAzNUEzLjU2NSAzLjU2NSAwIDAgMSAwIDEyLjQ5MmEzLjMxOCAzLjMxOCAwIDAgMSAuOTIyLTIuMzE4bC4xNC0uMTQ3TDkuNTU4IDEuMjVBNC4wNzkgNC4wNzkgMCAwIDEgMTIuNTExIDBjMS4xMzYtLjAwMSAyLjI4Ny40NjMgMy4xNjUgMS4zN0E0LjcwNyA0LjcwNyAwIDAgMSAxNyA0LjYzNmE0LjM2NCA0LjM2NCAwIDAgMS0xLjIxIDMuMDVsLTcuMTMyIDcuMzctLjUzMS41NDgtMS4wNi0xLjA5N0wxNC43MyA2LjU5Yy41MTMtLjUzLjc2OC0xLjIyNi43Ny0xLjk1M2EzLjEzIDMuMTMgMCAwIDAtLjg4Ni0yLjE3MSAyLjkzMiAyLjkzMiAwIDAgMC0yLjEwMy0uOTE1IDIuNTk5IDIuNTk5IDAgMCAwLTEuODkuNzk1TDEuOTg0IDExLjI3Yy0uMzIyLjMzMi0uNDguNzYzLS40ODIgMS4yMjIgMCAuNDg1LjE4My45ODYuNTYyIDEuMzc3LjM3OS4zOS44NjIuNTggMS4zMzIuNThhMS42MzUgMS42MzUgMCAwIDAgMS4xODQtLjQ5Nmw2LjUxNC02LjczYS42NTUuNjU1IDAgMCAwIC4xODItLjQ3LjguOCAwIDAgMC0uMjI5LS41NTYuNzQ1Ljc0NSAwIDAgMC0uNTM4LS4yMzYuNjIuNjIgMCAwIDAtLjQ1My4xODhsLTUuNTQgNS43MjQtMS4wNjItMS4wOTYgNS41NC01LjcyNGMuNDE2LS40MzIuOTctLjY0NiAxLjUxNS0uNjQ0LjU3OCAwIDEuMTYuMjM2IDEuNi42OTEuNDQuNDU2LjY3IDEuMDU2LjY3IDEuNjU0IDAgLjU2LS4yMDcgMS4xMzQtLjYyMyAxLjU2NEw1LjY0IDE1LjA0OUEzLjEwNCAzLjEwNCAwIDAgMSAzLjQwMiAxNmgtLjAwNnoiIGZpbGw9IiMxOTc5QzMiLz48L3N2Zz4=");
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &-attach {
                        display: none;
                    }
                }
            }
        }

        /*&-delete{display:inline-block;margin-inline-start:10px;width:16px;height:16px;background:url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzNiAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTM1LjkxOCAyLjcxNEwzMy4yMDMgMCAxOC40MTggMTQuNzg3IDMuNjMzIDAgLjkxOCAyLjcxMyAxNS43MDUgMTcuNS45MiAzMi4yODcgMy42MzMgMzVsMTQuNzg1LTE0Ljc4NiAxNC43ODUgMTQuNzg1IDIuNzExLTIuNzEzTDIxLjEzMSAxNy41IDM1LjkxOCAyLjcxNCIgZmlsbD0iIzAwNmJiNCIvPjwvc3ZnPg==');background-repeat:no-repeat;cursor:pointer;}*/
        
        &-delete {
            display: inline-block;
            margin-inline-start: 10px;
            cursor: pointer;
        }

        &-header {
            &-block {
                @include desktop {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid var(--color-gray85);
                    padding-block-end: 10px;
                }

                .amrma {
                    &-cancel {
                        align-self: center;
                    }
                }
            }
        }

        &-return {
            &-number {
                @include desktop {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                @include mobile {
                    margin-block-end: 15px;
                    border-bottom: 1px solid var(--color-gray85);
                    padding-block-end: 10px;
                }

                .amrma {
                    &-header {
                        margin: 0;
                        font-size: 24px;
                        line-height: 30px;

                        @include mobile {
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }

                    &-status {
                        @include desktop {
                            margin-inline-start: 10px;
                        }
                    }
                }
            }
        }

        &-bank-details,
        &-return-instructions,
        &-header-block {
            margin-block-end: 40px;
        }

        &-status {
            display: inline-block;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 11px;
            line-height: 14px;
            font-weight: 600;
            text-transform: uppercase;
            color: var(--color-white);

            &.-pending {
                background-color: #2196f3;
            }

            &.-authorized {
                background-color: #3f51b5;
            }

            &.-received {
                background-color: #9c27b0;
            }

            &.-resolved {
                background-color: #4caf50;
            }

            &.-cancelled {
                background-color: #9e9e9e;
            }

            &.-rejected {
                background-color: #c33d3c;
            }

            &-container {
                position: relative;
                box-sizing: content-box;
                margin-block-end: 50px;
                margin-inline: auto;
                max-width: 85%;

                @include mobile-small {
                    max-width: 80%;
                }

                .amrma {
                    &-progress {
                        position: absolute;
                        width: 100%;
                        height: 15px;
                        border-bottom: 2px solid var(--color-dark-charcoal);
                        z-index: 0;
                    }

                    &-percent {
                        position: absolute;
                        z-index: 1;
                        box-sizing: content-box;
                        width: 0;
                        height: 100%;
                        border-bottom: 2px solid var(--color-orange);

                        &.-status-0 {
                            width: 0;
                        }

                        &.-status-1 {
                            width: 33%;
                        }

                        &.-status-2 {
                            width: 67%;
                        }

                        &.-status-3,
                        &.-status-4 {
                            width: 100%;
                        }
                    }

                    &-steps {
                        position: relative;
                        display: flex;
                        justify-content: space-between;
                    }

                    &-step {
                        position: relative;
                        width: 16px;
                        height: 30px;

                        &::before,
                        &::after {
                            position: absolute;
                            border-radius: 50%;
                            content: "";
                        }

                        &::before {
                            inset-block-start: 6px;
                            inset-inline-start: 0px;
                            width: 16px;
                            height: 16px;
                            background-color: var(--color-dark-charcoal);
                            z-index: 1;
                        }

                        &::after {
                            inset-block-start: 3px;
                            inset-inline-start: -3px;
                            width: 22px;
                            height: 22px;
                            background-color: var(--color-white);
                            border: 1px solid var(--color-orange);
                            display: none;
                        }

                        &.-checked {
                            &::before {
                                background-color: var(--color-orange);
                            }

                            &::after {
                                display: block;
                            }
                        }

                        &.-complete {
                            &::before {
                                inset-block-start: 6px;
                                inset-inline-start: 5px;
                                background-color: transparent;
                                border-radius: inherit;
                                border-block-end: 2px solid var(--color-white);
                                border-inline-end: 2px solid var(--color-white);
                                width: 6px;
                                height: 12px;
                                transform: rotate(40deg);
                            }

                            &::after {
                                display: block;
                                background-color: var(--color-orange);
                                border: 0px;
                            }
                        }

                        &.-failed {
                            &::before {
                                inset-block-start: 3px;
                                width: 20px;
                                height: 20px;
                                background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTAiIGN5PSIxMCIgcj0iMTAiIGZpbGw9IiNEODBGMzMiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE0LjA0IDcuMzczYTEgMSAwIDAgMC0xLjQxNC0xLjQxNEwxMCA4LjU4NSA3LjM3NCA1Ljk2QTEgMSAwIDEgMCA1Ljk2IDcuMzczTDguNTg2IDEwIDUuOTYgMTIuNjI2YTEgMSAwIDEgMCAxLjQxNCAxLjQxNEwxMCAxMS40MTRsMi42MjYgMi42MjZhMSAxIDAgMCAwIDEuNDE0LTEuNDE0bC0yLjYyNi0yLjYyNyAyLjYyNi0yLjYyNnoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=");
                            }
                        }
                    }

                    &-name {
                        position: absolute;
                        inset-inline-start: 50%;
                        inset-block-end: -15px;
                        font-weight: 600;
                        white-space: nowrap;
                        -webkit-transform: translate3d(-50%, 0, 0);
                        transform: translate3d(-50%, 0, 0);
                        font-size: 14px;
                        line-height: 14px;

                        @include mobile-small {
                            font-size: 12px;
                            line-height: 12px;
                        }

                        @include mobile-exsmall {
                            font-size: 10px;
                            line-height: 10px;
                        }
                    }
                }

                .amrma-step.-resolved .-checked .amrma-name,
                .amrma-step.-pending .-checked .amrma-name {
                    max-width: 150px;
                }

                .amrma-step.-resolved.-checked {
                    display: flex;
                    justify-content: flex-end;
                    margin: 0;
                    text-align: end;
                }

                .amrma-step.-resolved.-checked::before {
                    inset-inline-end: 0;
                    padding: 0 20px 0 0;
                    border-radius: inherit;
                }

                .amrma-step.-resolved.-checked::before.-received.-checked::before {
                    inset-inline-start: 30%;
                }
            }
        }

        &-stars {
            &-container {
                box-sizing: border-box;
                margin-inline: auto;
                margin-block-end: 40px;
                padding: 15px;
                max-width: 530px;
                border: 2px solid var(--color-gray85);
                border-radius: 4px;
                background: var(--color-white);

                .rated {
                    &-message {
                        text-align: center;
                        font-weight: 600;
                        font-size: 1.6rem;
                        margin-block-end: 10px;
                    }
                }
            }
        }

        &-rating {
            &-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .amrma {
                    &-title {
                        line-height: 22px;
                        font-weight: 600;
                        font-size: 14px;
                    }
                }
            }
        }

        &-feedback {
            &-container {
                display: flex;
                box-sizing: border-box;
                margin: 15px;
                padding: 10px;
                border: 1px solid var(--color-gray85);
                border-radius: 2px;

                .amrma {
                    &-feedback {
                        margin-inline-end: 10px;
                        width: 80%;
                        height: 50px;
                        border: none;
                    }
                }

                .Button {
                    width: auto;
                }

                @include mobile-small {
                    margin-inline: 0px;
                    margin-block-end: 0px;

                    .amrma {
                        &-feedback {
                            padding-inline-start: 10px;
                            padding-inline-end: 0px;
                        }
                    }
                }
            }
        }

        &-customer {
            &-information {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin-block-end: 40px;
            }

            &-name,
            &-address {
                padding: 15px;
                display: flex;
                flex-direction: column;
                justify-content: start;
                border: 1px solid var(--color-gray85);
            }

            &-address {
                margin-inline-start: -1px;
            }

            @include mobile-small {
                &-information {
                    grid-template-columns: 1fr;
                }

                &-address {
                    margin-inline-start: 0px;
                    margin-block-start: -1px;
                }
            }
        }

        &-return {
            &-instructions {
                border: 2px solid var(--color-gray85);
            }
        }

        &-instructions {
            display: none;

            &-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                padding: 15px;

                .amrma {
                    &-title {
                        margin: 0px;
                    }

                    &-show {
                        position: relative;
                        width: 30px;
                        height: 30px;
                        box-sizing: border-box;
                        border: 2px solid var(--color-black);
                        border-radius: 50%;

                        &::before {
                            position: absolute;
                            width: 10px;
                            inset-block-start: 18%;
                            inset-inline-start: 28%;
                            height: 10px;
                            border-left: 1px solid var(--color-black);
                            border-top: 1px solid var(--color-black);
                            content: "";
                            transform: rotate(225deg);
                        }
                    }
                }
            }

            &:checked ~ {
                .amrma {
                    &-instruction {
                        &-block {
                            display: block;
                            padding: 15px;
                            border-block-start: 2px solid var(--color-gray85);

                            p {
                                &:last-child {
                                    margin-block-end: 0px;
                                }
                            }

                            img {
                                width: inherit !important;
                                height: inherit !important;
                            }
                        }
                    }
                }
            }

            &:checked + {
                .amrma {
                    &-instructions {
                        &-title {
                            .amrma {
                                &-show {
                                    &::before {
                                        inset-block-start: 38%;
                                        transform: rotate(45deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &-table {
            &-container {
                .amrma {
                    &-image {
                        width: 100px;
                        height: 100px;
                    }
                }

                strong {
                    font-weight: 600;
                }
            }
        }

        &-product {
            &-name {
                font-size: 14px;
            }
        }
    }
}
