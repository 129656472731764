/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --image-default-background: var(--secondary-base-color);
    --image-content-color: #{$black};
}

.Image {
    display: inline-block;
    overflow: hidden;
    height: 0;
    width: 100%;
    background: var(--image-default-background);

    &_ratio {
        &_16x9 {
            padding-block-end: 56.25%;
        }

        &_4x3 {
            padding-block-end: 75%;
        }

        &_square {
            padding-block-end: 100%;
        }
    }

    &_imageStatus_image_loading,
    &_isPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
    }

    &_hasSrc,
    &_imageStatus_image_loaded {
        background: none;
    }

    &-WidthFull {
        //width: 100%;

        td & {
            max-width: 100%;
            display: block;
            margin: auto;
        }
    }

    &-HeightFull {
        //height: 100%;

        td & {
            height: auto;
            display: block;
            margin: auto;
        }
    }

    &-Content {
        position: absolute;
        height: max-content;
        width: 100%;
        text-align: center;
        margin: auto;
        inset-block-end: 0;
        inset-block-start: 0;
        text-transform: capitalize;
        inset-inline-start: 0;
        color: var(--image-content-color);
        font-size: 10px;

        &_isOffline {
            width: 100%;
            height: 100%;
            background: var(--image-default-background);
        }
    }

    &-Loader {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
    }

    &-Image {
        position: absolute;
        inset-block-start: 50%;
        //inset-inline-start:50%;
        inset-inline-start: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%) translateY(-50%);
        //object-position: 50% 50%;
        object-fit: contain;
        color: transparent;

        &::after {
            content: "";
            position: absolute;
            z-index: -1;
            inset-block-start: 0;
            inset-inline-start: 0;
            width: 100%;
            height: 100%;
            background: var(--image-default-background);
        }
    }
}
