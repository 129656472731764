/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.Breadcrumb {
    $crumb-padding: 5px;

    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding-inline-end: $crumb-padding;

    @include mobile {
        font-size: 14px;
    }

    .ChevronIcon {
        filter: invert(57%) sepia(0%) saturate(1%) hue-rotate(105deg)
            brightness(93%) contrast(90%);
        display: inline-block;
        margin-inline-start: $crumb-padding;
        width: 12px;
        height: 12px;
    }

    &:last-child {
        .ChevronIcon {
            display: none;
        }

        :link {
            pointer-events: none;
        }
    }

    &::before {
        content: none;
    }

    &-Link {
        color: var(--breadcrumbs-color);
        display: inline-block;
        font-weight: 400;
        font-size: 11px;

        &:hover {
            color: var(--link-color);
        }
    }
}
