/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --footer-content-height: 220px;
    --footer-copyright-height: 50px;
    --footer-nav-height: 48px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));
}

.Footer {
    $column-count: 4;
    background-color: #f2f2f2;

    @include desktop {
        padding-block-start: 50px;
        padding-block-end: 50px;
    }

    &-CopyrightContent {
        padding: 30px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mobile {
            padding: 0px 0 30px;
        }
    }

    &-Copyright {
        font-size: 12px;
        text-align: center;
        padding-inline: 15px;
        line-height: 16px;

        @include mobile {
            text-align: start;
        }

        a {
            color: inherit;
            font-weight: 400;
            font-size: 12px;

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
    }

    &-Columns {
        height: 100%;
        display: grid;
        grid-auto-rows: max-content;

        @include mobile {
            flex-direction: column;
            align-items: center;
            padding: 30px 15px;
        }

        @include desktop {
            padding-inline: 15px;
            grid-template-columns: repeat(4, 1fr);
        }

        @include narrow-desktop {
            padding-inline: 15px;
        }
    }

    &-Column {
        @include mobile {
            width: 100%;
            border-bottom: 1px solid #878787;
            margin-block-end: 0px !important;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 30px;
            }

            @include desktop {
                padding-inline-end: 15px;
            }
        }

        &:first-child {
            .Footer-ColumnContent {
                @include mobile {
                    padding-block-end: 60px;
                    padding-block-start: 10px;
                    padding-inline-start: 0px;
                }
            }
        }
    }

    &-Socsubs {
        @include mobile {
            .Footer-ColumnTitle {
                padding: 0px;
                text-align: center;

                &::after {
                    display: none;
                }

                &::before {
                    display: none;
                }
            }
        }
    }

    &-ColumnTitle {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        position: relative;
        font-weight: 500;

        @include desktop {
            margin-block-end: 10px;
        }

        @include mobile {
            padding: 14px 11px 14px;
            margin-block-end: 0px;

            &:before {
                content: "";
                background-color: #{$black};
                position: absolute;
                inset-block-start: 50%;
                inset-inline-end: 11px;
                width: 16px;
                height: 2px;
                margin-block-start: -1px;
            }

            &::after {
                content: "";
                background-color: #{$black};
                position: absolute;
                inset-block-start: 50%;
                inset-inline-end: 18px;
                width: 2px;
                height: 16px;
                margin-block-start: -8px;
            }

            &-hide {
                &::after {
                    @include mobile {
                        display: none;
                    }
                }
            }
        }
    }

    &-socialic {
        display: flex;
        padding-block-start: 15px;

        @include mobile {
            justify-content: center;
        }

        li {
            width: 26px;
            height: 26px;
            margin: 0 8% 0 0;

            @include mobile {
                margin: 0 30px 0 0;
            }

            &::before {
                display: none;
            }

            a {
                svg {
                    width: 26px;
                    height: 26px;
                    fill: #333333;

                    &:hover {
                        fill: var(--color-orange);
                    }
                }
            }

            &:last-child {
                margin-inline-end: 0px;
            }
        }
    }

    &-links {
        li {
            &::before {
                display: none;
            }

            a {
                color: #{$black};
                font-weight: 400;
                font-size: 14px;
                line-height: 32px;

                &:hover {
                    color: var(--color-orange);
                }

                @include mobile {
                    line-height: 28px;
                    text-align: start;
                    display: block;
                }
            }

            &:last-child {
                margin-block-end: 0px;
            }
        }
    }

    &-subsection {
        padding-block-start: 60px;

        .Button {
            width: 100%;
            max-width: 228px;
            text-transform: none;
        }

        .Footer-ColumnTitle {
            @include mobile {
                padding-block-start: 5px;
                padding-block-end: 15px;
                text-align: center;
            }
        }

        @include mobile {
            text-align: center;

            &::before {
                inset-inline-start: 0;
                inset-inline-end: 0;
                inset-block-start: 35px;
                margin: 0 auto;
                width: 105px;
                background-color: #878787;
                position: absolute;
                content: "";
                height: 1px;
            }
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }

        &-hide {
            @include mobile {
                display: none;
            }
        }

        @include mobile {
            padding: 0px 0px 25px 10px;
        }
    }

    &-ColumnItem {
        border-radius: 2px;
        color: inherit;
        margin-block-end: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &:last-of-type {
            margin: 0;
        }

        &:hover {
            text-decoration: underline;
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                /* stylelint-disable-next-line declaration-no-important */
                width: 16px !important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 40px;
            height: 40px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-inline-start: 100px;
            }
        }
    }
}