@charset "UTF-8";

:root {
    --slick-arrow-bg: #{$white};
    --slick-arrow-color: #{$black};

    --slick-dot-border-color: var(--slick-arrow-color);
    --slick-dot-color: var(--slick-arrow-bg);

    --slick-dot-border-color-active: var(--slick-arrow-color);
    --slick-dot-color-active: var(--slick-arrow-color);
}

// Default Variables
$slick-loader-path: "./" !default;
$slick-opacity-on-hover: 1 !default;

@mixin rotate($degrees) {
    -webkit-transform: rotate($degrees);
    -moz-transform: rotate($degrees);
    -ms-transform: rotate($degrees);
    -o-transform: rotate($degrees);
    transform: rotate($degrees);
}

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }

    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Arrows */

.slick-arrow {
    position: absolute;
    inset-block-start: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    z-index: 1;
    border: none;
    outline: none;
    /*background-color:var(--slick-arrow-bg);*/
    background-color: rgba(255, 255, 255, 0.65);
    font-size: 0px;
    width: 30px;
    height: 50px;
    padding: 0px;

    &::before {
        content: "";
        width: 13px;
        height: 13px;
        border-bottom: 2px solid var(--slick-arrow-color);
        border-left: 2px solid var(--slick-arrow-color);
        position: absolute;
        inset-block-start: 19px;
    }

    &.slick-prev {
        inset-inline-start: 0px;

        &::before {
            @include rotate(45deg);

            inset-inline-start: 12px;
        }

        [dir="rtl"] & {
            inset-inline-start: auto;

            &::before {
                @include rotate(-135deg);

                inset-inline-start: 6px;
            }
        }
    }

    &.slick-next {
        inset-inline-end: 0px;

        &::before {
            @include rotate(-135deg);

            inset-inline-start: 6px;
        }

        [dir="rtl"] & {
            inset-inline-end: auto;

            &::before {
                @include rotate(45deg);

                inset-inline-start: 12px;
            }
        }
    }

    &.slick-disabled {
        cursor: default; display:none;
        /*opacity: 0.25;*/
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-block-end: 30px;
}

.slick-dots {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0px;
    list-style: none;
    text-align: center;

    li {
        position: relative;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        &::before {
            display: none;
        }

        button {
            vertical-align: top;
            font-size: 0;
            line-height: 0;
            display: inline-block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            color: transparent;
            outline: none;
            background-color: var(--slick-dot-color);
            border-radius: 50%;
            border: 1px solid var(--slick-dot-border-color);

            &:hover,
            &:focus {
                outline: none;

                &::before {
                    opacity: $slick-opacity-on-hover;
                }
            }
        }

        &.slick-active button {
            background-color: var(--slick-dot-color-active);
            border-color: var(--slick-dot-border-color-active);
        }
    }
}
