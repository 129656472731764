/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

%loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    span {
        position: absolute;
        width: 6px;
        height: 6px;
        background: #f68927;
        border-radius: 50%;
        animation: Loader-Scale 1.2s linear infinite;

        &:nth-child(1) {
            animation-delay: 0s;
            inset-block-start: 37px;
            inset-inline-start: 66px;
        }
        
        &:nth-child(2) {
            animation-delay: -0.1s;
            inset-block-start: 22px;
            inset-inline-start: 62px;
        }

        &:nth-child(3) {
            animation-delay: -0.2s;
            inset-block-start: 11px;
            inset-inline-start: 52px;
        }

        &:nth-child(4) {
            animation-delay: -0.3s;
            inset-block-start: 7px;
            inset-inline-start: 37px;
        }

        &:nth-child(5) {
            animation-delay: -0.4s;
            inset-block-start: 11px;
            inset-inline-start: 22px;
        }

        &:nth-child(6) {
            animation-delay: -0.5s;
            inset-block-start: 22px;
            inset-inline-start: 11px;
        }

        &:nth-child(7) {
            animation-delay: -0.6s;
            inset-block-start: 37px;
            inset-inline-start: 7px;
        }

        &:nth-child(8) {
            animation-delay: -0.7s;
            inset-block-start: 52px;
            inset-inline-start: 11px;
        }

        &:nth-child(9) {
            animation-delay: -0.8s;
            inset-block-start: 62px;
            inset-inline-start: 22px;
        }

        &:nth-child(10) {
            animation-delay: -0.9s;
            inset-block-start: 66px;
            inset-inline-start: 37px;
        }

        &:nth-child(11) {
            animation-delay: -1s;
            inset-block-start: 62px;
            inset-inline-start: 52px;
        }

        &:nth-child(12) {
            animation-delay: -1.1s;
            inset-block-start: 52px;
            inset-inline-start: 62px;
        }
    }
}

@keyframes Loader-Scale {
    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }
}
