/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --demo-notice-height: 0px;
    --demo-notice-bg: var(--color-orange);
    --header-logo-height: 50px;
    --header-logo-width: 260px;
    --header-nav-height: 96px;

    @include desktop {
        --header-top-menu-height: 32px;
        --header-total-height: calc(var(--header-nav-height) + var(--demo-notice-height) + var(--menu-total-height));
    }

    @include mobile {
        --header-nav-height: 65px;
        --header-total-height: calc(var(--header-nav-height) + var(--demo-notice-height));
    }

    @at-root .isDemoVisibleNew {
        --demo-notice-height: 40px;
    }
}

@mixin button-invisible {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    width: 0;
    height: 0;
}

@mixin button-visible {
    opacity: 1;
    height: 25px;
    width: 25px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin visible-on-mobile {
    @include button-visible;

    @include desktop {
        @include button-invisible;
    }
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.Header-CustomNotice {
    align-items: center;
    text-align: center;
    display: flex;
    padding-inline: 15px;
    font-weight: 700;
    justify-content: center;
    position: fixed;
    inset-block-start: 0;
    width: 100%;
    font-size: 16px;
    z-index: 100;
    /*z-index: 11;*/
    color: $white;
    background-color: var(--demo-notice-bg);
    height: var(--demo-notice-height);

    @include desktop {
        font-size: 16px;
        line-height: 24px;
    }

    @include mobile {
        font-size: 12px;
        line-height: 14px;
    }
}

.Header {
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 100;
    display: flex;
    flex-direction: column;
    background: var(--color-white);
    inset-block-start: var(--demo-notice-height);

    @include mobile {
        border-block-end: 1px solid var(--primary-divider-color);
    }

    >* {
        max-width: var(--content-wrapper-width);
        width: 100%;
    }

    &_menu,
    &_menu_subcategory {
        .Header-Button_type {
            &_close {
                @include desktop {
                    @include button-visible;
                }
            }

            &_menu {
                @include desktop {
                    @include button-invisible;
                }
            }
        }
    }

    &_name {
        &_menu {}

        &_search,
        &_menu_subcategory {
            @include mobile {
                border-block-end: none;
            }
        }
    }

    &-Button {
        @include button-invisible;

        &_isVisible {
            @include mobile {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.1;
        }

        &_type {
            &_menu {
                @include button-visible;
                width: 28px;
                margin-inline-start: 35px;
            }

            &_account {
                @include button-visible;
            }

            &_home {
                @include visible-on-mobile;
            }

            &_back {
                .ChevronIcon {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                }
            }

            &_close {
                display: none;

                .CloseIcon {
                    width: 27px;
                    height: 27px;
                }
            }

            &_share {
                @include button-visible;
            }

            &_compare {
                @include button-visible;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-Icon {
        display: flex;
        justify-content: end;

        @include mobile {
            padding-block-start: 2px;
            min-width:175px;
        }

        >li {
            &:last-child {
                margin-inline-end: 0px;
            }

            @include tablet {
                margin-inline-end: 15px;
            }

            @include narrow-desktop {
                margin-inline-end: 25px;
            }

            @include wide-desktop {
                margin-inline-end: 30px;
            }

            @include mobile {
                margin-inline-end: 10%;
            }

            @include mobile-small {
                margin-inline-end: 3.5%;
            }

            @include mobile-exsmall {
                margin-inline-end: 2%;
            }
        }

        .Header-compare {
            svg {
                width: 27px;
                height: 25px;
            }

            .ItemCount {
                inset-block-start: -8px !important;
                inset-inline-start: 19px !important;
            }
        }

        button,
        .Header-compare {
            cursor: pointer;
            text-align: center;
            position: relative;
            display: block;

            svg {
                fill: var(--color-black);
            }

            .lebal {
                display: block;
                font-size: 12px;
                padding-block-start: 5px;
                color: var(--color-black);
                line-height: 15px;
            }

            .ItemCount {
                background: var(--color-orange);
                border-radius: 50%;
                color: var(--color-white);
                height: 18px;
                min-width: 18px;
                padding: 2px;
                line-height: 14px;
                font-size: 10px;
                position: absolute;
                inset-block-start: -8px;
                inset-inline-start: 50%; margin-inline-start: -4px;
            }

            &:hover,
            &:active {
                svg {
                    fill: var(--color-orange);
                }

                .lebal {
                    color: var(--color-orange);
                }
            }

            @include mobile {
                svg {
                    height: 23px;
                }

                .lebal {
                    font-size: 10px;
                    padding-block-start: 3px;
                }
            }

            @include mobile-small {
                svg {
                    height: 19px;
                }
            }

            @include mobile-exsmall {
                svg {
                    height: 16px;
                }

                .lebal {
                    font-size: 9px;
                }
            }
        }
    }

    &-Icon-menu {
        position: absolute;
        inset-inline-start: 15px;
        inset-block-start: 50%;
        transform: translate3d(0%, -50%, 0);

        .svg-menu {
            fill: var(--color-dark-charcoal);
            stroke: var(--color-black);
            float: inline-start;
        }

        @include mobile-small {
            .svg-menu {
                width: 21px;
                height: 17px;
            }
        }

        @include mobile-exsmall {
            inset-inline-start: 10px;

            .svg-menu {
                width: 22px;
                height: 18px;
            }
        }
    }

    &-CompareButtonWrapper {
        a {
            all: initial;
        }
    }

    &-LogoWrapper {
        height: var(--header-logo-height);
        width: var(--header-logo-width);

        @include desktop {
            inset-block-start: 23px;
            inset-inline-start: 0;
            inset-inline-start: 15px;
            position: absolute;
        }

        @include mobile {
            inset-block-start: 3px;
            position: relative;

            svg {
                width: 142px;
                height: 28px;
            }
        }

        @include mobile-exsmall {
            width: 127px;
            height: 25px;

            svg {
                width: 127px;
                height: 25px;
            }
        }
    }

    &-Nav {
        align-items: center;
        display: grid;
        height: var(--header-nav-height);
        margin: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 15px;
        justify-content: unset;
        grid-template-columns: minmax(var(--header-logo-width), 1.1fr) minmax(auto, 725px) 1fr;

        @include desktop {
            padding-block: 23px;
        }

        @include mobile {
            padding-block: 8px;
            padding-inline-start: 60px;
            justify-content: space-between;
        }

        @include mobile-small {
            padding-inline-start: 46px;
        }

        @include mobile-exsmall {
            padding-inline-start: 37px;
            padding-inline-end: 10px;
        }
    }

    &-News {
        align-items: center;
        display: flex;

        &Button {
            font-size: 12px;
            margin-inline-start: 5px;
        }

        .ExclamationMarkIcon {
            inset-block-end: 1px;
            height: 16px;
            margin-inline-end: 10px;
            width: 16px;
        }
    }

    &-Title {
        color: var(--header-color);
        margin: auto;
        display: none;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 0;
        text-align: center;
        text-overflow: ellipsis;
        inset-block-start: -1px;
        inset-inline-start: 0;
        inset-block-end: 0;
        transition-duration: 200ms;
        transition-property: opacity;
        white-space: nowrap;
        width: 100%;
        text-transform: inherit;
        line-height: 30px;
        height: 30px;

        @include mobile {
            line-height: 28px;
        }

        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 80px);
            }
        }
    }

    &-Welcome {
        display: none;
        font-size: 14px;
        margin-inline-end: 16px;
        text-align: end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 200px;
        align-self: center;
        line-height: 16px;

        @include wide-desktop {
            display: block;
        }
    }

    &-TopMenu {
        display: flex;
        justify-content: space-between;
        height: var(--header-top-menu-height);
        margin: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 15px;
        padding-block-start: 16px;
    }

    &-Switcher {
        align-items: center;
        display: flex;
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: var(--header-total-height);

        @include mobile {
            z-index: 801;
        }

        &_isPrerendered {
            @include mobile {

                .Header-TopMenu,
                .Menu-MenuWrapper {
                    display: none;
                }

                .Header-Nav {
                    &>* {
                        display: none;
                    }

                    .Header {

                        &-Title,
                        &-LogoWrapper,
                        &-Button {
                            &_isVisible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    &_name_popup {
        @include mobile {
            z-index: 450;
        }
    }

    &_isCheckout {
        padding-block: 31px;
        border-block-end: 1px solid #c4c4c4;

        .Header {
            &-Icon {
                &-menu {
                    display: none;
                }
            }

            &-LogoWrapper {
                position: relative;
                inset-block-start: inherit;
                inset-inline-start: 0px;
            }
        }

        &_row {
            display: grid;
            grid-template-columns: 260px auto;
            align-items: center;
            justify-content: space-between;
        }

        &_Contact {
            display: grid;
            grid-template-columns: auto auto auto auto;
            grid-gap: 30px;
            align-items: center;

            @include desktop {
                grid-gap: 20px;
            }
            @include wide-desktop {
                grid-gap: 50px;
            }

            @include narrow-desktop {
                grid-gap: 50px;
            }
            

            >li {
                >a.link-black {
                    font-size: 14px;
                    line-height: 24px;
                    display: block;

                    .icon {
                        padding-inline-end: 8px;
                        display: inline-block;

                        svg {
                            fill: var(--color-black);

                            &.svg-phon {
                                fill: none;
                                stroke: var(--color-black);
                            }
                        }
                    }

                    &:hover,
                    &:active {
                        .icon {
                            svg {
                                fill: var(--color-orange);

                                &.svg-phon {
                                    fill: none;
                                    stroke: var(--color-orange);
                                }
                            }
                        }
                    }
                }
            }
        }

        @include mobile {
            padding-block: 15px;

            .Header {
                &-LogoWrapper {
                    width: 151px;
                    height: 29px;
                }
            }

            &_row {
                grid-template-columns: 151px auto;
            }

            &_Contact {
                grid-template-columns: auto;

                >li {
                    >a {
                        img {
                            width: 85px;
                            height: 29px;
                        }
                    }
                }
            }
        }
    }

    &-Cookieuse {
        position: fixed;
        inset-inline-end: 0px;
        inset-block-end: 0px;
        padding: 15px;
        /*background: #009de2;*/
        background-color: #005C8A;
        color: var(--color-white);
        max-width: 480px;
        z-index: 801;
        opacity: 0.9;

        h5 {
            padding-block-end: 5px;
            font-size: 14px;
        }

        p {
            font-size: 12px;
            color: var(--color-white);
        }

        a {
            text-decoration: underline;
            cursor: pointer;
            color: var(--color-white);
        }

        &-Btn {
            border: 2px solid #fff;
            display: block;
            padding: 10px 25px;
            cursor: pointer;
            background: none;
            color: var(--color-white);
            margin-block-start: 15px;
            border-radius: 4px;
            text-transform: uppercase;
            width: 110px;
            margin: 0 auto;
        }
    }

    &-browser-update {
        position: fixed;
        inset-block-end: 0px;
        inset-inline-start: 0px;
        inset-inline-end: 0px;
        padding: 10px 15px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        z-index: 1;
        background: var(--primary-info-color);
    }

    &-compare{
        &-bottom{
            position: fixed;right:20px;bottom:20px;z-index: 6;
            svg{margin-right:5px; fill:#fff;}
            .ItemCount{ display:inline-block; padding-right:3px;}
        }        
    }
}

.flipswitch {
    position: relative;
    width: 50px;
    display: table-cell;
    vertical-align: middle;
    float: inline-end;

    @include mobile-small {
        width: 40px;
    }

    @include mobile-exsmall {
        width: 35px;
    }

    &-label {
        pointer-events: auto;
        display: block;
        overflow: hidden;
        cursor: pointer;
        margin-block-start: 1px;
        margin-block-end: 2px;

        @include mobile {
            margin-block-end: 0px;
        }

        @include mobile-small {
            margin-block-start: 0px;
        }

        @include mobile-exsmall {
            margin-block-start: 1.5px;
        }
    }

    &-inner {
        width: 200%;
        margin-inline-start: -100%;

        &::before,
        &::after {
            background-color: var(--color-white);
            border: 1px solid var(--color-dark-charcoal);
            border-radius: 50px;
            color: var(--color-dark-charcoal);
            float: inline-start;
            width: 50%;
            height: 22px;
            line-height: 20px;
            font-size: 12px;

            @include mobile-small {
                height: 19px;
                line-height: 18px;
                font-size: 10px;
            }

            @include mobile-exsmall {
                height: 16px;
                line-height: 14px;
                font-size: 10px;
            }
        }

        &::before {
            content: "on";
            border-color: var(--color-orange);
            color: var(--color-orange);
            padding-inline-start: 7px;

            @include mobile-small {
                padding-inline-start: 4px;
            }

            @include mobile-exsmall {
                padding-inline-start: 2px;
            }
        }

        &::after {
            content: "off";
            padding-inline-end: 8px;
            text-align: end;

            @include mobile-small {
                padding-inline-end: 4px;
            }

            @include mobile-exsmall {
                padding-inline-end: 3px;
            }
        }
    }

    &-switch {
        width: 22px;
        height: 22px;
        background-color: var(--color-dark-charcoal);
        border: 0;
        border-radius: 50px;
        position: absolute;
        inset-block-start: 0;
        inset-block-end: 0;
        inset-inline-end: 28px;

        @include mobile-small {
            width: 19px;
            height: 19px;
            inset-inline-end: 20px;
        }

        @include mobile-exsmall {
            width: 16px;
            height: 16px;
            inset-inline-end: 18px;
        }
    }

    .lebal {
        display: block;
        font-size: 12px;
        padding-block-start: 5px;
        text-align: center;
        line-height: 14px;

        @include mobile {
            font-size: 10px;
            padding-block-start: 4px;
            line-height: 11px;
        }

        @include mobile-exsmall {
            font-size: 9px;
        }
    }

    &-cb {
        display: none;

        &:checked {
            +.flipswitch-label {
                .flipswitch-inner {
                    margin-inline-start: 0px;
                }

                .flipswitch-switch {
                    inset-inline-end: 0px;
                    background-color: var(--color-orange);

                    &::before {
                        content: "";
                        transform: rotate(45deg);
                        width: 5px;
                        height: 10px;
                        border-right: 2px solid var(--color-white);
                        border-bottom: 2px solid var(--color-white);
                        position: absolute;
                        inset-block-start: 5px;
                        inset-inline-start: 9px;

                        @include mobile-small {
                            inset-block-start: 3px;
                            inset-inline-start: 7px;
                        }

                        @include mobile-exsmall {
                            inset-block-start: 3px;
                            inset-inline-start: 6px;
                        }
                    }
                }
            }
        }
    }
}
