/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.ExpandableContentShowMore {
    text-align: start;

    &-ShowAllButton.Button_likeLink {
        --button-height: fit-content;
        --button-hover-height: fit-content;
        --button-hover-padding: 0;
        color: var(--primary-base-color);
        margin-block-start: 8px;
        font-weight: 300;
        font-family: $font-common;
        border-bottom: solid 1px var(--primary-base-color);
        padding-block-end: 3px;
        border-radius: 0;
        text-transform: capitalize;

        &:focus:not(:hover),
        &:hover {
            text-decoration: none;
            border-radius: 0;
        }
    }

    &-ExpandableChildren {
        transition: 0.5s;
        overflow: hidden;
    }
}
