/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: #0a0903;
    --breadcrumbs-color: var(--color-dark-charcoal);
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    @include mobile {
        --breadcrumbs-background: #fff;

        display: none;
    }

    &-List {
        list-style: none;
        padding: 15px 0;

        @include mobile {
            padding: 6px 0 8px;
        }
    }
}

@include mobile {
    body.ProductPage-body-ClickCollect .Breadcrumbs {
        display: none;
    }
}
