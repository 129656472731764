/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import "../../style/abstract/variables";
@import "../../style/abstract/media";
@import "../../style/abstract/button";
@import "../../style/abstract/loader";
@import "../../style/abstract/icons";
@import "../../style/abstract/parts";

.BranchOverlay {
    -webkit-transform: translate(0px);
    -moz-transform: translate(0px);
    -o-transform: translate(0px);
    transform: translate(0px);
    -webkit-transition: -webkit-transform 400ms ease;
    -moz-transition: -moz-transform 400ms ease;
    -o-transition: -o-transform 400ms ease;
    transition: transform 400ms ease;

    &-Open {
        overflow: hidden;
        padding-inline-end: 17px;

        .BranchOverlay {
            @include desktop {
                transform: translate(-385px);
            }

            @include mobile {
                transform: translate(-100%);
            }
        }

        .BranchOverlay-transparent-bg {
            display: block;
        }

        .Header {
            margin-inline-end: -17px;

            &-CustomNotice {
                margin-inline-end: -17px;
            }
        }
    }

    &-transparent-bg {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        inset-inline-start: 0px;
        position: fixed;
        top: 0px;
        width: 100%;
        z-index: 101;
        display: none;

        @include mobile {
            z-index: 802;
        }
    }
}

.BranchOverlay {
    position: fixed;
    opacity: 1;
    height: 100%;
    inset-block-start: 0px;
    inset-inline-end: 0px;
    pointer-events: auto;
    z-index: 999;

    @include desktop {
        width: 385px;
        margin-inline-end: -385px;
    }

    &:not(.Overlay_isStatic) {
        @include mobile {
            inset-inline-start: auto;
            inset-block-start: 0px;
            inset-inline-end: 0px;
            width: 100%;
            margin-inline-end: -100%;
            height: 100%;
            padding-block-end: 0px;
        }
    }

    &-ContentWrapper {
        position: absolute;
        inset-block-start: 0px;
        inset-block-end: 0px;
        inset-inline-start: 0px;
        inset-inline-end: 0px;
    }

    &-Step1 {
        display: flex;
        flex-flow: row;
        flex-direction: column;
        position: absolute;
        inset-block-start: 0px;
        inset-block-end: 0px;
        inset-inline-start: 0px;
        inset-inline-end: 0px;

        .BranchOverlay {
            &-Heading,
            &-ExpandableContent {
                flex: initial;
            }

            &-loop {
                flex: 6;
            }
        }
    }

    &-Step2 {
        display: flex;
        flex-flow: row wrap;
        flex-direction: column;
        position: absolute;
        inset-block-start: 0px;
        inset-block-end: 0px;
        inset-inline-start: 0px;
        inset-inline-end: 0px;

        .BranchOverlay {
            &-Heading,
            &-back {
                flex: initial;
            }

            &-Detailcontent {
                flex: 6;

                .BranchItem-map {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    &-Detailcontent {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &-back {
        text-align: center;

        .link-black {
            display: block;
            text-align: center;
            padding: 20px;
            font-size: 12px;

            @include mobile {
                padding: 15px;
            }

            svg {
                margin-inline-end: 5px;
            }
        }
    }

    &-Closebutton {
        cursor: pointer;
    }

    &-Heading {
        background-color: #f2f2f2;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-block-end: 1px solid #c4c4c4;

        span {
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            color: var(--color-black);
        }

        + .BranchOverlay-ExpandableContent {
            margin-block-start: -1px;
        }

        @include mobile {
            padding: 15px;

            span {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    &-ExpandableContent {
        background-color: var(--color-cultured);
        border-block-end: 1px solid #c4c4c4;
        border-block-start: 1px solid #c4c4c4;
        padding: 20px;

        &Button {
            padding: 0px;
        }

        &Content {
            padding: 0px;
        }

        @include mobile {
            padding: 15px;
        }
    }

    &-ExpandableContentHeading {
        font-size: 16px;
        font-weight: 500;
        color: var(--color-black);

        @include mobile {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &-Search {
        &InnerWrapper {
            position: relative;
        }

        .Field {
            margin-block-start: 5px;

            &-Label {
                font-size: 12px;
                line-height: 14px;
                font-weight: 400;
                color: var(--color-dark-charcoal);
                padding-block-end: 5px;

                &_isRequired {
                    color: var(--required-text-color);
                }
            }

            input {
                width: 100%;
                padding-inline-end: 45px;
                font-size: 12px;
            }
        }

        .BranchItem {
            &-SearchSvg {
                position: absolute;
                inset-inline-end: 15px;
                inset-block-start: 35px;

                .svg-search {
                    fill: var(--color-taupe-gray);
                }
            }
        }
    }

    .BranchItem {
        padding: 20px;

        strong {
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            color: var(--color-black);
            display: inline-block;
        }

        p {
            font-size: 11px;
            line-height: 14px;
            font-weight: 400;
            color: var(--color-dark-charcoal);
            margin-block-end: 0px;
        }

        &-miles {
            padding-block-start: 10px;

            p {
                font-weight: 600;
            }
        }

        &-row {
            display: grid;
            column-gap: 43px;
            grid-template-columns: 1fr 1fr;
            padding-block: 10px;
            align-items: center;
        }

        &-Directions {
            font-size: 11px;
            line-height: 13px;
            font-weight: 400;
            cursor: pointer;
            color: var(--color-black);
            display: inline-block;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
                color: var(--color-orange);
            }
        }

        &-right {
            p {
                padding-block-start: 3px;
                display: block;
                font-weight: 500;
                color: var(--color-black);
            }
        }

        &-time {
            p {
                font-weight: 500;
                color: var(--color-black);
            }
        }

        &-loop {
            padding-inline: 20px;
            /*height:calc(100vh - 129px);*/
            overflow-y: auto;
            overflow-x: hidden;

            li {
                .BranchItem {
                    padding-inline: 0px;
                    border-block-end: 1px solid #c4c4c4;
                }
            }
        }

        @include mobile {
            padding: 15px;

            &-row {
                column-gap: 15px;
                padding-block: 5px;
            }

            &-loop {
                padding-inline: 15px;
            }
        }
    }
}
